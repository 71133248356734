import React from "react";
import { EventManager } from "../../Events";
import "./style.css";
import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import ChartGraph from "Components/ChartGraph";
import AgGridView from "Components/ChartGraph/partials/AgGridView";
import { EntityTableView } from "Components/ChartGraph/EntityTableView";
import { AccountDelveView } from "Components/ChartGraph/AccountDelveView";
import allAccountLedgers from "../../helpers/ledgers/accountsAndLedgers.json";
import BottomPanelTabControls from "Components/ChartGraph/BottomPanelTabControls";
import ScenariosPanel from "../../Components/ScenariosPanel";
import RightDisplayPanel from "../../Components/RightDisplayPanel";
import { ScenarioView } from "Components/ScenarioView";
import NodeMenuContainer from "../../Components/EventsMenu";
// import DisclaimerPopUp from "../../Components/DisclaimerPopUp"; <== commented while component gets ready to be used
import Options from "../../Components/Options";
import moment from "moment";
import BottomPanelExpandIcon from "../../Assets/tabsIcons/expand-arrow-only.svg";
// import TableChartImg from "../../Assets/tabsIcons/table-chart.png"; Never used, not sure if required
import LeaderboardImg from "../../Assets/tabsIcons/pie-charts.png";
import MapImg from "../../Assets/tabsIcons/map.jpg";
// import CreateNewScenarioThumbnail from "../../Assets/tabsIcons/create-new-scenario.png";
// import RentVsBuyScenarioThumbnail from "../../Assets/tabsIcons/rent-vs-buy-example.png";
import KnowledgeBaseImg from "../../Assets/tabsIcons/KnowledgeBaseImage.png";
import EmptyEventImage from "../../Assets/tabsIcons/updated-empty-event.png";
import { EventsToast } from "../../Components/EventsToast";
import OnboardingModal from "../../Components/OnboardingModal";
import {
    decisionMetadata,
    house1Metadata,
    mortgage1Metadata,
    incomeTaxMetadata,
    mortgageDecision,
    mortgage2Metadata,
} from "../../helpers/onboardingInputs";
import { EventsModal } from "../../Components/EventsModal";
import { WELCOME } from "../../actions/types";
import { LaunchOnboarding } from "../../helpers/onboardingFunctions";
import { Mixpanel } from "../../helpers/mixpanel";
import { Modifier, Pension, MMMMToMM } from "../../helpers/constants";
import {
    AnyNode,
    recurseToModifier,
    getBaselineProperties,
    ValueReplacer,
    PercentChange,
    SpecificNode,
    AddToValue,
} from "../../helpers/modifierHelpers";
import {
    extractProvinceFromAddress,
    extractCityFromAddress,
    processNodeValueForCanvasDisplay,
    valueDoesNotExist,
} from "../../helpers";
// import { getRelevantLedgers } from "../../helpers/ledgers/ledgerDependencies";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    AutoStart,
    DefaultSetting,
} from "../../helpers/refinanceRenewalConstants";
import * as uuid from "uuid";
import {
    DownpaymentAmount,
    DownpaymentRate,
} from "../../helpers/downpaymentHelpers";
import { isActiveUserAdmin } from "../../helpers/userHelpers";
import { Student_IncomeObject } from "../../Components/Registry/Student Income";
import { rentalIncomeObject } from "../../Components/Registry/Rental Income";
import { expenseObject } from "../../Components/Registry/Expense";
import { tuitionObject } from "../../Components/Registry/Tuition";
import { budgetObject } from "../../Components/Registry/Budget";
import { studentBudgetObject } from "../../Components/Registry/Student Budget";
import { retirementObject } from "../../Components/Registry/Retirement";
import { graduationObject } from "../../Components/Registry/Graduation";
import { loanObject } from "../../Components/Registry/Loan";
import { studentLoanObject } from "../../Components/Registry/Student Loan";
import { debtObject } from "../../Components/Registry/Debt";
import { debtRepaymentObject } from "../../Components/Registry/Debt Repayment";
import { customerObject } from "../../Components/Registry/Customer";
import { grantObject } from "../../Components/Registry/Grant";
import { customerGrowthObject } from "../../Components/Registry/CustomerGrowth";
import { customerChurnObject } from "../../Components/Registry/CustomerChurn";
import { unitCostObject } from "../../Components/Registry/Unit Cost";
import { revenueObject } from "../../Components/Registry/Revenue";
import { bankObject } from "../../Components/Registry/Bank";
import { incomeObject } from "../../Components/Registry/Income";
import { houseObject } from "../../Components/Registry/House";
import { rentObject } from "../../Components/Registry/Rent";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { rrspObject } from "Components/Registry/RRSP";
import { investmentObject } from "Components/Registry/Investment";
import { mortgageObject } from "Components/Registry/Mortgage";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyValueObject } from "Components/Registry/Property Value";
import { employeeObject } from "Components/Registry/Employee";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { pensionObject } from "Components/Registry/Pension";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { goalObject } from "Components/Registry/Goal";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { modifierObject } from "Components/Registry/Modifier";
import { meObject } from "Components/Registry/Me";
import { personObject } from "Components/Registry/Person";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { equityObject } from "Components/Registry/Equity";
import { UltraZoomThreadButtonExperimental } from "Components/UltraZoomThreadButtonExperimental/UltraZoomThreadButtonExperimental";
import { PasteOptionModal } from "Components/PasteOptionModal/PasteOptionModal";
import { CopyScenarioModal } from "Components/CopyScenarioModal/CopyScenarioModal";
import { upsertAllAccountLedgers } from "actions/allAccountLedgersActions";
import { upsertCalculatedThreads } from "actions/calculatedThreadsActions";
import { generateContextForMortgage } from "helpers/getThreadContext";
import { deepDiff } from "helpers/deepDiff";
import { maternityObject } from "Components/Registry/Maternity";
import { condoFeeObject } from "../../Components/Registry/CondoFee";
import { cacObject } from "Components/Registry/CAC";
import { nullObject } from "Components/Registry/Null";
import { containerObject } from "Components/Registry/Container";
import { contractObject } from "../../Components/Registry/Contract";
import { startObject } from "Components/Registry/Start";
import { businessObject } from "Components/Registry/Business";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { getEarliestStartDate } from "helpers/getEarliestStartDate";
import { getModifiedRange } from "helpers/getModifiedRange";
import { isPastAndDoNotCalculate } from "helpers/isPastAndDoNotCalculate";
import { isBefore, stringsToDates } from "helpers/stringToDateHelpers";
import store from "store";
// import { GUEST } from "../../helpers/constants";
import { decisionObject } from "Components/Registry/Decision";
import { CanvasStage } from "Components/EventsCanvas/Experimental/CanvasStage";
import { DashboardSpeedDial } from "Components/DashboardSpeedDial/index";
import {
    createCadenceArray,
    dateStringToOverrideDateString,
    deleteModifiers,
    getDefaultValue,
    getModifierForCustomer2,
    getNextOverrideDateString,
    getOverrideByField,
    getOverrides,
    getSimplifiedSegmentMap,
    updateModifiers,
    updateOverrideStartDate,
} from "helpers/modifiers";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { calcDateDiff } from "Components/InputContainer/OnInputChangeHandlers/contractInputsHandler";
import { kpiObject } from "Components/Registry/KPI";
import { instanceObject } from "Components/Registry/Instance";
import { modifier2Object } from "Components/Registry/Modifier2";
import { groupObject } from "Components/Registry/Group";
import {
    formatGroupInstance,
    formatProjectInstance,
    formatData,
} from "helpers/instanceHelpers";
import { evaluateExpression } from "helpers/expressionHelper";
import { SelectionFilter } from "Components/SelectionFilter";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import Modal from "@mui/material/Modal";
import { addRelevantLedger } from "helpers/ledgers/ledgers";
import { growthObject } from "Components/Registry/Growth";
import { salespersonObject } from "Components/Registry/Salesperson";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { segmentObject } from "Components/Registry/Segment";
import { loadScenario } from "actions/scenarioHelpers";
import { getModifierCustomStartDate } from "Components/InputContainer/ModifierInputExperimental/ModifierInputExperimental";
import eventToAccounts from "../../helpers/ledgers/eventToAccounts.json";
import { getCards } from "actions/canvasCardActions";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { getAmortizedEndDate } from "Components/InputContainer/OnInputChangeHandlers/expenseInputsHandler";
import { unitObject } from "Components/Registry/Unit";
import { recurseToTopAccount } from "helpers/accounts";
import incrementDateByCadence from "helpers/incrementDateByCadence";
import { utilizationObject } from "Components/Registry/Utilization";

// const MAP_TAB_FLAG_EXPERIMENTAL = [
//     "dev.whatifi.io",
//     "localhost",
//     "127.0.0.1",
// ].includes(window.location.hostname);

/* TODOs
    Future
    - Root Profiles/Including other scenarios
    - Modifier node - multipliers downstream, ie. +10% expenses
    */

class Main extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.manager = new EventManager(
            this.updateCanvas,
            this.updateScenarioCanvas
        );

        this.state = {
            calculateTimer: null,
            lastCalculate: null,
            nodes: [],
            scenarioThreads: [],
            scenarioCalculations: [],
            highlightedThread: {},
            displayScenarios: 5,
            showScenarios: true,
            optionType: null,
            parents: null,
            children: null,
            filterThread: false,
            currentDisplay: "monthly",
            width: 800,
            height: 182,
            showNodeMenu: true,
            dimensions: {
                width: window.innerWidth * 0.8,
                height: window.innerHeight * 0.9,
            },
            deleteNode: false,
            toastData: null,
            exitTourModal: false,
            optionSelected: null,
            reload: false,
            showRightDisplay: true,
            rightDisplayContent: "ScenarioView",
            graphPanelTabSelected: "closed",
            showGraphPanel: false,
            promptScenarioCreation: false,
            sampleScenarioCreation: false,
            promptScenarioEdit: false,
            sampleScenarioEdit: false,
            showDropdown: false,
            allEvents: undefined,
            firstHouseEvent: undefined,
            excludedBaselineNodes: [],
            allHouseEvents: [],
            selectedThreadsWithEvents: [],
            selectedBottomPanelView: "allThreads",
        };

        this.container = null;
    }

    updateDimensions() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (window.innerWidth < 800) {
            this.setState({ width: 450, height: 102, landingPage: false });
        } else if (!loggedInUser) {
            this.setState({ landingPage: true });
        } else {
            let update_width = window.innerWidth - 100;
            let update_height = Math.round(update_width / 4.4);
            this.setState({ width: update_width, height: update_height });
        }
    }

    handleBottomPanelViews = (view) => {
        this.setState({ selectedBottomPanelView: view });
    };

    togglePromptScenarioCreation = (trueOrFalse) => {
        this.setState({ promptScenarioCreation: trueOrFalse });
    };

    toggleSampleScenarioCreation = (trueOrFalse) => {
        this.setState({ sampleScenarioCreation: trueOrFalse });
    };

    setSampleScenarioCreation = (trueOrFalse) => {
        this.setState({ sampleScenarioCreation: trueOrFalse });
    };

    togglePromptScenarioEdit = (trueOrFalse) => {
        this.setState({ promptScenarioEdit: trueOrFalse });
    };

    setSampleScenarioEdit = (trueOrFalse) => {
        this.setState({ sampleScenarioEdit: trueOrFalse });
    };

    setDisplay = (display) => {
        this.setState({ currentDisplay: display });
    };

    cloneObject = (object) => Object.assign({}, object);

    renameObjectKey = (object, currentKey, newKey) => {
        const clonedObject = this.cloneObject(object);
        const keyToRename = clonedObject[currentKey];

        delete clonedObject[currentKey];
        clonedObject[newKey] = keyToRename;
        return clonedObject;
    };

    exportForCalculations = async (details = this.state) => {
        const { nodes, scenarioThreads } = details;
        const { loadedScenario } = this.props;
        if (!loadedScenario || loadedScenario.is_archived) return;
        // const manager = this.modelManager;
        const manager = this.manager;

        const startNode = manager.getRootNode();
        const startEntity = Object.values(
            getRelevantEntities(startNode.entities.map((entity) => entity.id))
        )[0];

        const nodesWithBaseline = [...nodes];
        const nodesWithIncludedBaseline = [...nodes];

        // if (manager.baseline) {
        //     manager.baseline.forEach((node) => {
        //         nodesWithBaseline.push(node);

        //         // Takes into consideration if the Node is in the Excluded Baseline Nodes
        //         if (
        //             !(
        //                 startEntity?.data.excludedBaselineNode ?? []
        //             ).includes(node.id)
        //         ) {
        //             nodesWithIncludedBaseline.push(node);
        //         }
        //     });
        // }

        if (nodes && scenarioThreads && loadedScenario) {
            const exportEntities = {};
            const modifierNodes = {};
            const implicitModifiers = {};
            const exportOverrides = {};
            let exportInstances = {};
            let ledgerMap = {};
            //used for toggling off in instances
            let toDelete = [];
            const exportModifiers = {};

            const scenarioStartDate = this.getScenarioStartDate();
            // This date is the earliest start date of any entity within the scenario
            // (or the scenarioStartDate if it's earlier), DE3 will begin calculations
            // on this day. In contrast to scenarioStartDate which is the date that the display graph will start on.
            const scenarioCalculationStart = getEarliestStartDate(
                this.props.loadedScenario,
                scenarioStartDate
            );

            // We take the difference between the scenario start date and the calculation start date and
            // increase the scenario range by the difference. This modified range allows DE3 to calculate
            // from the earliest start date all the way to the end of the scenario. This modified range is
            // a number that represents months instead of years.
            const modifiedRange = getModifiedRange(
                scenarioStartDate,
                scenarioCalculationStart,
                loadedScenario.range
            );

            //this line of code creates refresh bug if you don't check if there is node
            for (let node of nodesWithIncludedBaseline) {
                if (!node.valid || !node.isFilled || node.isBypassed()) {
                    continue;
                }
                const entities = getRelevantEntities(
                    node.entities.map((entity) => entity.id)
                );
                for (let entity of Object.values(entities)) {
                    // Copy entity data to avoid mutations
                    entity = { ...entity };
                    entity.data = { ...entity.data };
                    // Also copy overrides and any implicit modifiers if applicable
                    if (entity?.data?.modsCreated?.length)
                        entity.data.modsCreated = entity.data.modsCreated.map(
                            (mod) => {
                                return { ...mod };
                            }
                        );

                    //entity is bypassed
                    if (entity.bypassState) {
                        continue;
                    }

                    if (!entity.calculateState) {
                        toDelete.push(entity.id);
                    }
                    // Check to filter out entities that we don't want to calculate in the past and contribute to ledgers
                    if (
                        entity.startDate &&
                        isPastAndDoNotCalculate(entity, scenarioStartDate)
                    ) {
                        if (
                            entity.cadence === "one-time" ||
                            (entity.endDate &&
                                isBefore(entity.endDate, scenarioStartDate))
                        ) {
                            // Entity is entirely in the past, or only occurs once in the past and we do not want it to contribute
                            // to cumulative ledgers so we do not export it and simply skip over it
                            continue;
                        }
                        // If the entity has a valid cadence then we increment its start date by the cadence until the start date
                        // occurs after the scenario start date
                        if (entity?.cadence) {
                            while (
                                isBefore(entity.startDate, scenarioStartDate)
                            ) {
                                entity.startDate = incrementDateByCadence(
                                    entity.startDate,
                                    entity.cadence
                                );
                            }
                        } else {
                            // Otherwise the entity begins in the past and may continue to the present, however we still do not want
                            // its past calculations to contribute to cumulative ledgers to so we set its start date to the scenario start date
                            entity.startDate = scenarioStartDate;
                            // Also update overrides if applicable
                            if (entity?.data?.modsCreated?.length)
                                updateOverrideStartDate(
                                    entity.data.modsCreated,
                                    entity.startDate
                                );
                        }
                    }
                    // Grab all relevant modifiers from the specific entity and ready them for export to calculation engine
                    if (entity.id in this.props.modifiers) {
                        if (loadedScenario.type !== "shared") {
                            exportOverrides[entity.id] = await updateModifiers(
                                this.props.modifiers,
                                entity.id
                            );
                        } else {
                            exportOverrides[entity.id] =
                                this.props.modifiers[entity.id];
                        }
                    }

                    // Add account paths for events that utilize the same static accounts/ledgers
                    if (
                        eventToAccounts[entity.type] &&
                        !entity.data.accountIdsPaths
                    )
                        entity.data.accountIdsPaths =
                            eventToAccounts[entity.type].accounts;

                    // gross quick fix
                    switch (entity.type) {
                        case loanObject.constant(): {
                            const cashPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];
                            // const loanAmount = entity.data.loanPremium ? entity.data.loan + entity.data.loanPremium : entity.data.loan

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                cadence: entity.cadence,
                                scenarioStartDate: scenarioStartDate,
                                loan: entity.data.loan ?? 0,
                                premium: entity.data.loanPremium ?? 0,
                                periodicInterestRate:
                                    entity.data.periodicInterestRate ||
                                    entity.data.interestRate / (12 * 100),
                                loanAccountId: entity.data.loanAccountId,
                                interestAccountId:
                                    entity.data.interestAccountId,
                                premiumAccountId: entity.data.premiumAccountId,
                                payment: entity.data.value,
                                loanType: entity.data.loanType,
                                isInterest:
                                    entity.data.interestVsPremium !== "premium",
                                interestPaymentsOnly:
                                    entity.data.interestPaymentsOnly &&
                                    entity.data.loanType === "standard",
                                revenueAccountId:
                                    entity.data.dependantAccountId ?? "",
                                revenuePercentage:
                                    entity.data.revenuePercentage / 100,
                                accountIdsPaths: [
                                    ...entity.data.accountIdsPaths,
                                    cashPath,
                                ],
                                paymentStart: entity.data.paymentStart,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case studentLoanObject.constant(): {
                            let dateArray = entity.metadata.start.split("-");
                            let year = parseInt(dateArray[0]);
                            let month = parseInt(dateArray[1]);
                            month = month + 6;
                            if (month > 12) {
                                year++;
                                month = month - 12;
                            }
                            if (month < 10) {
                                month = "0" + month;
                            }
                            const repaymentStartDate =
                                year + "-" + month + "-" + dateArray[2];
                            const metadata = {
                                id: entity.id,
                                nodeType: studentLoanObject.constant(),
                                startDate: entity.metadata.start,
                                governmentLoan:
                                    entity.metadata.governmentLoan ===
                                    "government"
                                        ? true
                                        : false,
                                fullTime:
                                    entity.metadata.governmentLoan ===
                                    "full-time"
                                        ? true
                                        : false,
                                graduationDate: entity.metadata.governmentLoan
                                    ? repaymentStartDate
                                    : entity.metadata.start,
                                termInterest: entity.metadata.termInterest,
                                numPayments: entity.metadata.numPayments,
                                loanAmount: entity.metadata.loan,
                                cadence: entity.metadata.cadence,
                                loanInterestRate:
                                    Number(entity.metadata.rate) / 100,
                                inflation: entity.metadata.inflation,
                                owner: entity.metadata.owner,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case mortgageObject.constant(): {
                            const startDate = moment(
                                entity.metadata["first payment"]
                            ).format("YYYY-MM-DD");

                            const modifiedRRSPOne = [],
                                modifiedRRSPTwo = [];
                            if (entity.metadata.rrspNodes) {
                                for (const rrsp of entity.metadata.rrspNodes) {
                                    if (
                                        entity.metadata.personOne &&
                                        rrsp.personId ===
                                            entity.metadata.people[
                                                entity.metadata.personOne
                                            ]
                                    ) {
                                        modifiedRRSPOne.push(rrsp.id);
                                    }
                                    if (
                                        entity.metadata.personTwo &&
                                        rrsp.personId ===
                                            entity.metadata.people[
                                                entity.metadata.personTwo
                                            ]
                                    ) {
                                        modifiedRRSPTwo.push(rrsp.id);
                                    }
                                }
                            }

                            const rrspModOneId = uuid.v4();
                            const rrspModOne = {
                                id: rrspModOneId,
                                nodeType: Modifier,
                                typesToModify: ["Mortgage"],
                                startDate: startDate,
                                action: "set",
                                idsToModify: modifiedRRSPOne,
                                value: entity.metadata.rrspContributionOne,
                                keyToModify: "value",
                                cadence: "one-time",
                                misc: {
                                    config: "deduct max",
                                    downpaymentId: entity.id,
                                },
                            };
                            const rrspModTwoId = uuid.v4();
                            const rrspModTwo = {
                                id: rrspModTwoId,
                                nodeType: Modifier,
                                typesToModify: ["Mortgage"],
                                startDate: startDate,
                                action: "set",
                                idsToModify: modifiedRRSPTwo,
                                value: entity.metadata.rrspContributionTwo,
                                keyToModify: "value",
                                cadence: "one-time",
                                misc: {
                                    config: "deduct max",
                                    downpaymentId: entity.id,
                                },
                            };
                            const houseParent = entity.parents.find(
                                (node) => node.type === "House"
                            );
                            const metadata = {
                                id: entity.id,
                                // nodeType: node.metadata.nodeType,
                                nodeType: "Mortgage",
                                isNew: entity.metadata.isNew,
                                isDownpayment: entity.metadata.isDownpayment,
                                price: parseInt(entity.metadata.price),
                                term: parseInt(
                                    entity.metadata["mortgage term"]
                                ),
                                startDate: startDate,
                                cadence: entity.metadata.cadence,
                                baseline: entity.metadata.baseline,

                                province: houseParent
                                    ? extractProvinceFromAddress(
                                          houseParent.metadata.address
                                      )
                                    : "",

                                downpaymentData: {
                                    lastChangedDownpaymentProperty:
                                        entity.metadata
                                            .lastChangedDownpaymentProperty ||
                                        DownpaymentRate,
                                    downpaymentRate:
                                        parseInt(
                                            entity.metadata
                                                .inputtedDownpaymentRate ||
                                                entity.metadata.downpaymentRate
                                        ) / 100,
                                    value: parseInt(
                                        entity.metadata.inputtedDownpayment ||
                                            entity.metadata.downpayment
                                    ),
                                },
                                mortgageData: {
                                    cadence: entity.metadata.cadence,
                                    rate: Number(entity.metadata.rate),
                                    amortizationPeriod: parseInt(
                                        entity.metadata["amortization period"]
                                    ),
                                    payments: entity.metadata.payments,
                                },
                                modsCreated: [rrspModOneId, rrspModTwoId],
                            };
                            exportEntities[rrspModOneId] = rrspModOne;
                            exportEntities[rrspModTwoId] = rrspModTwo;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case downpaymentObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                // nodeType: node.metadata.nodeType,
                                nodeType: downpaymentObject.constant(),
                                cadence: entity.metadata.cadence,
                                startDate: entity.metadata.startDate,
                                name: entity.metadata.name,
                                inflation: loadedScenario.inflation
                                    ? true
                                    : false,
                                inflationRate: loadedScenario.inflation,
                                value: Number(entity.metadata.downpayment),
                                downpaymentRate: Number(
                                    entity.metadata.downpaymentRate
                                ),
                                lastChangedDownpaymentProperty:
                                    entity.metadata
                                        .lastChangedDownpaymentProperty,
                                owner: loadedScenario.baselineid,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case propertyResaleObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                // nodeType: node.metadata.nodeType,
                                nodeType: propertyResaleObject.constant(),
                                cadence: entity.metadata.cadence,
                                startDate: entity.metadata.start,
                                name: entity.metadata.name,
                                houseId: entity.metadata.houseId,
                                resalePrice: Number(
                                    entity.metadata.resalePrice
                                ),
                                closingCost: Number(
                                    entity.metadata.closingCost
                                ),
                                useCalculatedPrice:
                                    entity.metadata.houseValue ===
                                    "marketValue",
                                value: 0.0,
                                profit: entity.metadata.profit
                                    ? entity.metadata.profit
                                    : 0,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                oneOccurence: true,
                            };
                            // create/ add modifier for house and mortgage
                            const modId = uuid.v4();
                            const modifier = {
                                id: modId,
                                keyToModify: "houseNotActive",
                                typesToModify: ["House"],
                                nodeType: Modifier,
                                value: Number(entity.metadata.resalePrice),
                                startDate: entity.metadata.start,
                                action: "cancel",
                                idsToModify: [entity.metadata.houseId],
                                houseId: entity.metadata.houseId,
                                cadence: "one-time",
                            };
                            const rentalIncomeModId = uuid.v4();
                            const incomeMod = {
                                id: rentalIncomeModId,
                                keyToModify: "rentalIncomeEndDate",
                                nodeType: Modifier,
                                typesToModify: ["House"],
                                startDate: entity.metadata.start,
                                action: "set",
                                value: entity.metadata.start,
                                idsToModify: [entity.metadata.houseId],
                                houseId: entity.metadata.houseId,
                                cadence: "one-time",
                            };
                            metadata.modsCreated = [modId, rentalIncomeModId];

                            exportEntities[modId] = modifier;
                            exportEntities[rentalIncomeModId] = incomeMod;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case rentObject.constant(): {
                            // let metadata = {};
                            let inflationRate = Number(
                                loadedScenario.inflation
                            );
                            if (entity.metadata.rentalIncrease) {
                                inflationRate += parseFloat(
                                    entity.metadata.increaseRate / 100
                                );
                            }

                            const metadata = {
                                // nodeType: node.metadata.nodeType,
                                nodeType: rentObject.constant(),
                                value: entity.metadata.accomodationCost
                                    ? entity.metadata.accomodationCost
                                    : entity.metadata.rent,
                                inflation: entity.metadata.inflation,
                                inflationRate: inflationRate,
                                startDate: entity.metadata.start,
                                endDate: entity.metadata.end,
                                cadence: entity.metadata.cadence,
                                id: entity.id,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case goalObject.constant(): {
                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                start: moment(entity.startDate).format(
                                    "YYYY-MM-DD"
                                ),
                                value: entity.data.value,
                                name: entity.name,
                                accountType:
                                    entity.data.monthlyCumulative ===
                                    "cumulative"
                                        ? `Cumulative-${entity.data.accountId}`
                                        : entity.data.accountId,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case budgetObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: budgetObject.constant(),
                                name: entity.metadata.name,
                                value: entity.metadata.value,
                                cadence: entity.metadata.cadence,
                                budgetList: entity.metadata.budgetList
                                    .filter((budget) => {
                                        return budget.selected;
                                    })
                                    .map((budgetList) => {
                                        return {
                                            value: budgetList.budget,
                                            cadence: budgetList.cadence,
                                            id: budgetList.id,
                                            inflation: budgetList.inflation,
                                            inflationRate:
                                                budgetList.inflationRate,
                                            selected: budgetList.selected,
                                            showSubCategory:
                                                budgetList.showSubCategory,
                                            subCategoriesList:
                                                budgetList.subCategoriesList,
                                            title: budgetList.title,
                                        };
                                    }),
                                inflationRate: Number(loadedScenario.inflation),
                                inflation: entity.metadata.inflation,
                                startDate: entity.metadata.startDate,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case studentBudgetObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: studentBudgetObject.constant(),
                                name: entity.metadata.name,
                                value: entity.metadata.value,
                                cadence: entity.metadata.cadence,
                                budgetList: entity.metadata.budgetList
                                    .filter((budget) => {
                                        return budget.selected;
                                    })
                                    .map((budgetList) => {
                                        return {
                                            value: budgetList.budget,
                                            cadence: budgetList.cadence,
                                            id: budgetList.id,
                                            inflation: budgetList.inflation,
                                            inflationRate:
                                                budgetList.inflationRate,
                                            selected: budgetList.selected,
                                            showSubCategory:
                                                budgetList.showSubCategory,
                                            subCategoriesList:
                                                budgetList.subCategoriesList,
                                            title: budgetList.title,
                                        };
                                    }),
                                inflationRate: Number(loadedScenario.inflation),
                                inflation: entity.metadata.inflation,
                                startDate: entity.metadata.startDate,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case homeMaintenanceObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: homeMaintenanceObject.constant(),
                                cadence: "monthly",
                                houseId: entity.metadata.houseId,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                startDate: entity.metadata.startDate,
                                budgetList: entity.metadata.budgetList
                                    .filter((budget) => {
                                        return budget.selected;
                                    })
                                    .map((budgetList) => {
                                        return {
                                            value: budgetList.budget,
                                            cadence: budgetList.cadence,
                                            id: budgetList.id,
                                            inflation: budgetList.inflation,
                                            inflationRate:
                                                budgetList.inflationRate,
                                            selected: budgetList.selected,
                                            showSubCategory:
                                                budgetList.showSubCategory,
                                            subCategoriesList:
                                                budgetList.subCategoriesList,
                                            title: budgetList.title,
                                        };
                                    }),
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case incomeTaxObject.constant(): {
                            let data = {
                                type: entity.type,
                                id: entity.id,
                                value: parseInt(entity.data["total_tax"]),
                                owner: entity.data.personId,
                                cadence: "monthly",
                                inflation: true,
                                inflationRate:
                                    Number(loadedScenario.inflation) / 100,
                                endDate: "2100-01-01",
                                startDate: "1990-01-01",
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case propertyTransferTaxObject.constant(): {
                            let metadata = entity.metadata;
                            metadata = this.renameObjectKey(
                                metadata,
                                "type",
                                "nodeType"
                            );
                            metadata["nodeType"] = "Property Transfer Tax";
                            const res = await this.getPttValues();

                            if (res.data && res.data.values) {
                                metadata["config"] = res.data.values;
                            }
                            metadata["cadence"] = "one-time";
                            metadata["value"] = entity.metadata.value
                                ? entity.metadata.value
                                : 0;
                            metadata["inflation"] = loadedScenario.inflation
                                ? true
                                : false;
                            metadata["inflationRate"] = Number(
                                loadedScenario.inflation
                            );
                            metadata["id"] = entity.id;
                            metadata["firstTimeBuyer"] =
                                entity.metadata.firstTimeBuyerTrue;
                            metadata["foreignBuyer"] =
                                entity.metadata.foreignBuyerTrue;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case realEstateFeeObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                cadence: entity.metadata.cadence,
                                name: entity.metadata.name,
                                startDate: entity.metadata.startDate,
                                nodeType: realEstateFeeObject.constant(),
                                lastChangedFeeProperty:
                                    entity.metadata.lastChangedFeeProperty,
                                houseId: entity.metadata.houseId,
                                resalePrice: entity.metadata.resalePrice,
                                value: entity.metadata.value,
                                enteredFeePercent:
                                    entity.metadata.enteredFeePercent,
                                enteredFeeAmount:
                                    entity.metadata.enteredFeeAmount,
                                inflation: entity.metadata.inflation,
                                inflationRate: entity.metadata.inflationRate,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case propertyValueObject.constant(): {
                            const valueModId = uuid.v4(),
                                apprecModId = uuid.v4();
                            const valueMod = {
                                id: valueModId,
                                nodeType: Modifier,
                                startDate: entity.startDate,
                                action: "set",
                                keyToModify: "value",
                                idsToModify: [entity.data.houseId],
                                typesToModify: ["House"],
                                value: entity.data.propertyValue,
                                cadence: "one-time",
                            };
                            const apprecMod = {
                                id: apprecModId,
                                nodeType: Modifier,
                                startDate: entity.startDate,
                                action: "set",
                                keyToModify: "inflationRate",
                                typesToModify: ["House"],
                                idsToModify: [entity.data.houseId],
                                value: entity.data.appreciation
                                    ? Number(entity.data.appreciationRate) / 100
                                    : 0,
                                cadence: "one-time",
                            };
                            const data = {
                                id: entity.id,
                                nodeType: "Property Value",
                                houseId: entity.data.houseId,
                                propertyValue: entity.data.propertyValue,
                                startDate: entity.startDate,
                                cadence: entity.cadence,
                                dateToday: entity.data.dateToday,
                                inflation: entity.data.inflation,
                                inflationRate: loadedScenario.inflation,
                                isPropertyRevalued:
                                    entity.data.isPropertyRevalued,
                                modsCreated: [valueModId, apprecModId],
                            };
                            exportEntities[valueModId] = valueMod;
                            exportEntities[apprecModId] = apprecMod;
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case bankObject.constant(): {
                            // Flinks is deprecated
                            // const flinksAccounts = [];
                            // const { bankData, selected } = node.metadata;
                            // if (bankData && bankData.Accounts && selected) {
                            //     selected.forEach((account) => {
                            //         flinksAccounts.push({
                            //             accountNumber: account.AccountNumber,
                            //             balance: account.Balance.Current,
                            //             category: account.Category,
                            //             type: account.Type,
                            //             id: account.Id,
                            //         });
                            //     });
                            // }
                            // Currently only functions for manually added bank accs
                            const data = {
                                id: entity.id,
                                value: Number(entity.data.value ?? 0),
                                type: entity.type,
                                bankSelected: entity.data.bankSelected,
                                acctCategories: entity.data.acctCategories,
                                currency: "CAD",
                                interestRate: Number(loadedScenario.inflation),
                                accounts: entity.data.accounts,
                                accountIdsPaths:
                                    entity.data.accountIdsPaths ?? [],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case equityObject.constant(): {
                            var today = new Date();
                            var dd = String(today.getDate() + 1).padStart(
                                2,
                                "0"
                            );
                            var mm = String(today.getMonth() + 1).padStart(
                                2,
                                "0"
                            ); //January is 0!
                            var yyyy = today.getFullYear();
                            today = yyyy + "-" + mm + "-" + dd;
                            const metadata = {
                                id: entity.id,
                                nodeType: equityObject.constant(),
                                cadence: "one-time",
                                startDate: today,
                                stocks: entity.metadata.stockList,
                                rating: entity.metadata.rating,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case incomeObject.constant(): {
                            const isOngoing =
                                entity?.data?.expenseType === "ongoing";

                            let endDate =
                                entity?.endDate ?? entity.data.amortizedEnd;
                            if (isOngoing && endDate == undefined) {
                                endDate = getAmortizedEndDate(
                                    entity?.startDate,
                                    entity?.cadence,
                                    entity?.data?.amortizationPeriod ?? 1
                                );
                            }

                            const numPayments = isOngoing
                                ? entity.data.numPayments
                                : moment(endDate).diff(
                                      moment(entity.startDate),
                                      "days"
                                  ) + 1;

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: isOngoing ? entity.cadence : "daily",
                                inflation: entity.data.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                subType: entity.data.incomeType
                                    ? entity.data.incomeType.split(" ").join("")
                                    : "",
                                startDate: entity.startDate,
                                endDate: endDate,
                                value: Number(entity.data.income),
                                rating: entity.data.rating,
                                expenseType:
                                    entity?.data?.expenseType ?? "ongoing",
                                numPayments: numPayments,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                ],
                                accountIds: entity.data.accountIds,
                                accountName: entity.data.accountName,
                                contraAccountIds: entity.data.contraAccountIds,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case taxDeductibleObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: taxDeductibleObject.constant(),
                                cadence: entity.metadata.cadence,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                owner: entity.metadata.personId,
                                startDate: entity.metadata.start,
                                endDate: entity.metadata.end,
                                value: Number(entity.metadata.taxDeductible),
                                rating: entity.metadata.rating,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case Student_IncomeObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: "Part Time Job",
                                cadence: entity.metadata.cadence,
                                inflation: entity.metadata.inflation,
                                wage: entity.metadata.wage,
                                hours: entity.metadata.hours,
                                inflationRate: Number(loadedScenario.inflation),
                                owner: entity.metadata.personId,
                                startDate: entity.metadata.start,
                                endDate: entity.metadata.end,
                                value: Number(entity.metadata.income),
                                rating: entity.metadata.rating,
                                disableUponGraduation:
                                    entity.metadata.disableUponGraduation,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case expenseObject.constant(): {
                            const isOngoing =
                                entity.data.expenseType === "ongoing";
                            const startDate = entity.startDate;
                            const endDate = isOngoing
                                ? entity.endDate
                                : entity.data.amortizedEnd;
                            const days =
                                moment(endDate).diff(
                                    moment(startDate),
                                    "days"
                                ) + 1;
                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: isOngoing ? entity.cadence : "daily",
                                startDate: startDate,
                                endDate: endDate,
                                expenseType: entity.data.expenseType,
                                rating: entity.data.rating,
                                name: entity.name,
                                value: isOngoing
                                    ? Number(entity.data.cost)
                                    : Number(entity.data.cost) / days,
                                inflation: isOngoing
                                    ? entity.data.applyInflation
                                    : false,
                                inflationRate: valueDoesNotExist(
                                    entity.data.inflationRate // legacy 2021-02-01
                                )
                                    ? Number(loadedScenario.inflation)
                                    : entity.data.inflationRate / 100,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                ],
                                accountIds: entity.data.accountIds,
                                accountName: entity.data.accountName,
                                contraAccountIds: entity.data.contraAccountIds,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case tuitionObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                // nodeType: node.metadata.nodeType,
                                nodeType: "Tuition",
                                cadence: entity.metadata.cadence,
                                startDate: entity.metadata.start,
                                endDate: entity.metadata.end,
                                expenseType: entity.metadata.expenseType,
                                numPayments: entity.metadata.numPayments,
                                rating: entity.metadata.rating,
                                name: entity.metadata.name,
                                value: entity.metadata.value,
                                inflationRate: valueDoesNotExist(
                                    entity.metadata.inflationRate // legacy 2021-02-01
                                )
                                    ? Number(loadedScenario.inflation)
                                    : entity.metadata.inflationRate / 100,
                                inflation: entity.metadata.applyInflation,
                                owner: entity.metadata.owner,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case rentalIncomeObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: rentalIncomeObject.constant(),
                                cadence: entity.metadata.cadence,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                owner: entity.metadata.personId,
                                startDate: entity.metadata.start,
                                endDate: entity.metadata.end,
                                value: Number(entity.metadata.income),
                                rating: entity.metadata.rating,
                                rentalExpenses: entity.metadata.rentalExpenses,
                                houseId: entity.metadata.houseId,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case debitCreditObject.constant(): {
                            const isOngoing =
                                entity.data.amortizedOrOngoing === "ongoing";
                            let data;
                            if (isOngoing) {
                                data = {
                                    id: entity.id,
                                    type: entity.type,
                                    cadence: entity.cadence,
                                    startDate: entity.startDate,
                                    endDate: entity.endDate,
                                    value: Number(entity.data.value),
                                    inflation: entity.data.inflation,
                                    inflationRate: Number(
                                        loadedScenario.inflation
                                    ),
                                    rating: entity.data.rating,
                                    accountIdsPaths: [
                                        entity.data.accountIds,
                                        entity.data.contraAccountIds,
                                    ],
                                    accountIds: entity.data.accountIds,
                                    contraAccountIds:
                                        entity.data.contraAccountIds,
                                    accountName: entity.data.accountName,
                                    debitOrCredit: entity.data.debitOrCredit,
                                };
                            } else {
                                // Whenever you give moment a date, it puts that date
                                // at 00:00, so something like 2024-01-01 and 2024-01-02
                                // are exactly 24 hours apart. However, DE includes that
                                // end date, and also puts the end time at 23:59, so
                                // 2024-01-01 and 2024-01-02 are a day and 23:59 hours
                                // apart.
                                const days =
                                    moment(entity.data.amortizedEnd).diff(
                                        moment(entity.startDate),
                                        "days"
                                    ) + 1;

                                const calculatedAmortizedValue =
                                    parseFloat(entity.data.amortizedValue) /
                                    days;

                                data = {
                                    id: entity.id,
                                    type: entity.type,
                                    cadence: "daily",
                                    startDate: entity.startDate,
                                    endDate: entity.data.amortizedEnd,
                                    value: Number(calculatedAmortizedValue),
                                    inflation: entity.data.inflation,
                                    inflationRate: Number(
                                        loadedScenario.inflation
                                    ),
                                    rating: entity.data.rating,
                                    accountIdsPaths: [
                                        entity.data.accountIds,
                                        entity.data.contraAccountIds,
                                    ],
                                    accountIds: entity.data.accountIds,
                                    contraAccountIds:
                                        entity.data.contraAccountIds,
                                    accountName: entity.data.accountName,
                                    debitOrCredit: entity.data.debitOrCredit,
                                };
                            }

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case expressionObject.constant(): {
                            // Giant ugly if statement check to ensure both event and entity exist, if either does not
                            // we set the value to 0. This is to avoid the scenario where an existing event or entity was
                            // deleted but the Expression event continues to store its value.
                            let val = 0;
                            if (entity.data.selectedEvent) {
                                const event = getEvent(
                                    entity.data.selectedEvent
                                );
                                if (
                                    event &&
                                    entity.data.selectedEntity &&
                                    getRelevantEntities(event.entities)[
                                        entity.data.selectedEntity
                                    ]
                                ) {
                                    // We evaluate the expression using the most up-to-date associated entity values
                                    // before we ship it to the decision engine. This is for the case where the user updates
                                    // the associated entity without editing the expression event itself.

                                    let entityModifiers = [];

                                    if (
                                        entity.data.expression.includes(
                                            "value"
                                        ) &&
                                        exportModifiers[
                                            entity.data.selectedEntity
                                        ]
                                    ) {
                                        entityModifiers = exportModifiers[
                                            entity.data.selectedEntity
                                        ].filter((mod) => {
                                            return mod.field == "value";
                                        });
                                    }

                                    const [evalResult, newModifier] =
                                        await evaluateExpression(
                                            entity.data.expression,
                                            entity,
                                            entityModifiers
                                        );
                                    entity.data.value = evalResult;
                                    if (newModifier) {
                                        const [_, overrideIndex] =
                                            getOverrideByField(
                                                exportOverrides[entity.id],
                                                "value"
                                            );
                                        if (
                                            overrideIndex != null &&
                                            overrideIndex > -1
                                        ) {
                                            exportOverrides[entity.id][
                                                overrideIndex
                                            ] = newModifier;
                                        } else {
                                            exportOverrides[entity.id]
                                                ? exportOverrides[
                                                      entity.id
                                                  ].push(newModifier)
                                                : (exportOverrides[entity.id] =
                                                      [newModifier]);
                                        }
                                    }

                                    val = !isNaN(+entity.data.value)
                                        ? Number(entity.data.value)
                                        : 0;
                                } else {
                                    const overrides = getOverrides(entity.id);
                                    if (overrides) {
                                        const [override, overrideIndex] =
                                            getOverrideByField(
                                                overrides,
                                                "value"
                                            );
                                        if (override) {
                                            exportOverrides[entity.id].splice(
                                                overrideIndex,
                                                1
                                            );
                                            await deleteModifiers([
                                                {
                                                    entity_id: entity.id,
                                                    modifier_id: override.id,
                                                },
                                            ]);
                                        }
                                    }
                                }
                            } else {
                                const overrides = getOverrides(entity.id);
                                if (overrides) {
                                    const [override, overrideIndex] =
                                        getOverrideByField(overrides, "value");
                                    if (override) {
                                        exportOverrides[entity.id].splice(
                                            overrideIndex,
                                            1
                                        );
                                        await deleteModifiers([
                                            {
                                                entity_id: entity.id,
                                                modifier_id: override.id,
                                            },
                                        ]);
                                    }
                                }
                            }

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                endDate: entity.endDate,
                                cadence: entity.cadence,
                                value: val,
                                debitOrCredit: entity.data.debitOrCredit,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                ],
                                accountIds: entity.data.accountIds,
                                contraAccountIds: entity.data.contraAccountIds,
                                accountName: entity.data.accountName,
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case debtObject.constant(): {
                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                endData: entity.endDate,
                                cadence: "daily",
                                value: Number(entity.data.debt),
                                interestType: entity.data.interestType,
                                interestRate: Number(entity.data.interestRate),
                                accountIds: entity.data.accountIds,
                                accountIdsPaths:
                                    entity.data.accountIdsPaths ?? [],
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case maternityObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: maternityObject.constant(),
                                cadence: "weekly",
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                mother: entity.metadata.personId,
                                motherStart: entity.metadata.start,
                                motherEnd: entity.metadata.end,
                                isSpouseEi: entity.metadata.spouseTakingTimeOff,
                                spouse: entity.metadata.spouseId,
                                spouseStart: entity.metadata.spouseStart,
                                spouseEnd: entity.metadata.spouseEnd,
                                extended: entity.metadata.extended,
                                value: Number(entity.metadata.income),
                                rating: entity.metadata.rating,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case grantObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: grantObject.constant(),
                                owner: entity.metadata.personId,
                                startDate: entity.metadata.start,
                                cadence: entity.metadata.cadence,
                                value: Number(entity.metadata.income),
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case debtRepaymentObject.constant(): {
                            const isFixed =
                                entity.data.fixedVsRemaining === "fixed";
                            const startDate = isFixed
                                ? entity.startDate
                                : entity.data.paymentDate;

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: startDate,
                                endDate: entity.endDate,
                                value: entity.data.value,
                                cadence: entity.cadence,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                ],
                                accountIds: entity.data.accountIds,
                                contraAccountIds: entity.data.contraAccountIds,
                                accountName: entity.data.accountName,
                                isFixed: isFixed,
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case customerObject.constant(): {
                            const topLevelCustomerAccountId =
                                "b2623ec6-3492-4fa0-9dcd-0150c3443cb0";
                            const subGrownAccountId =
                                "b93d043d-bf4c-423f-8a80-7702870a5ebb";
                            const subChurnedAccountId =
                                "fe6d5929-4afd-4c03-91cf-121164ff212e";

                            const grownAccountPath = [
                                topLevelCustomerAccountId,
                                subGrownAccountId,
                            ];
                            const churnedAccountPath = [
                                topLevelCustomerAccountId,
                                subChurnedAccountId,
                            ];

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                cadence: "one-time",
                                value: Number(entity.data.customer),
                                accountId: entity.data.parentAccountId,
                                grownAccountId: entity.data.grownAccountId,
                                churnedAccountId: entity.data.churnedAccountId,
                                transferredInAccountId:
                                    entity.data.transferredInAccountId,
                                transferredOutAccountId:
                                    entity.data.transferredOutAccountId,
                                accountIdsPaths: [
                                    ...(entity?.data?.accountIdsPaths ?? []),
                                    grownAccountPath,
                                    churnedAccountPath,
                                ],
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case customer2Object.constant(): {
                            const topLevelCustomerAccountId =
                                "b2623ec6-3492-4fa0-9dcd-0150c3443cb0";
                            const subGrownAccountId =
                                "b93d043d-bf4c-423f-8a80-7702870a5ebb";
                            const subChurnedAccountId =
                                "fe6d5929-4afd-4c03-91cf-121164ff212e";

                            const grownAccountPath = [
                                topLevelCustomerAccountId,
                                subGrownAccountId,
                            ];
                            const churnedAccountPath = [
                                topLevelCustomerAccountId,
                                subChurnedAccountId,
                            ];

                            // for CAC2
                            const expensePath = [
                                "7faf0285-78ca-411b-b875-d900929d7c94",
                            ];
                            const expenseContraPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                cadence: "monthly",
                                value: 0,
                                accountId: entity.data.parentAccountId,
                                grownAccountId: entity.data.grownAccountId,
                                churnedAccountId: entity.data.churnedAccountId,
                                transferredInAccountId:
                                    entity.data.transferredInAccountId,
                                transferredOutAccountId:
                                    entity.data.transferredOutAccountId,
                                accountIdsPaths: [
                                    ...(entity?.data?.accountIdsPaths ?? []),
                                    grownAccountPath,
                                    churnedAccountPath,
                                    expensePath,
                                    expenseContraPath,
                                ],
                            };

                            const overrideDateString =
                                dateStringToOverrideDateString(
                                    entity.startDate,
                                    "monthly"
                                );
                            const override = {
                                cadence: "monthly",
                                defaultValue: "0",
                                field: "value",
                                functions: {
                                    [overrideDateString]: "replace",
                                },
                                values: {
                                    [overrideDateString]: Number(
                                        entity.data.customer
                                    ),
                                },
                            };

                            exportEntities[entity.id] = data;
                            exportOverrides[entity.id] = [override];
                            break;
                        }
                        case customerGrowth2Object.constant(): {
                            const associatedEntityIds =
                                this.getEntityDependencies(
                                    entity.dependencies,
                                    "entity"
                                );

                            let entityThread = [];
                            // the every method breaks out of the loop if it encounters a falsy value
                            scenarioThreads.forEach((thread) => {
                                const { signature, nodes } = thread;
                                nodes.every((threadNode) => {
                                    if (threadNode.id === node.id) {
                                        entityThread.push(signature);
                                        // node exists in this thread so we exit the loop
                                        return false;
                                    }
                                    return true;
                                });
                            });

                            const scenarioLength = this.getScenarioLength();

                            for (const entityId of associatedEntityIds) {
                                let totalScenarioLength = scenarioLength;

                                let startDate = entity.startDate;

                                // these are for appending to the values and functions map in case the startDate for those are later than for the customer entity, in which case it messes up the overmods in de3 since when there are modifiers overrides are run at the same time and wouldn't be run if modifiers for a date doesn't exist
                                let extraValuesMap = {};
                                let extraFunctionsMap = {};

                                if (entity.data.inheritStartDate) {
                                    const modEntityStartDate =
                                        this.getCustomerDateDependencies(
                                            entityId
                                        );

                                    // growth starts one month after
                                    const newStartDate = moment(
                                        modEntityStartDate
                                    )
                                        .add(1, "M")
                                        .toISOString()
                                        .slice(0, 7)
                                        .concat("-01");

                                    startDate = newStartDate;

                                    // if the customer starts before the scenario start date, the scenario length becomes longer
                                    if (
                                        moment(startDate).isBefore(
                                            moment(scenarioStartDate)
                                        )
                                    ) {
                                        const monthsDifference = moment(
                                            scenarioStartDate
                                        ).diff(moment(startDate), "months");
                                        totalScenarioLength = Number(
                                            totalScenarioLength +
                                                monthsDifference / 12
                                        );
                                    }

                                    if (
                                        moment(modEntityStartDate).isBefore(
                                            moment(startDate),
                                            "months"
                                        )
                                    ) {
                                        extraValuesMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            totalScenarioLength,
                                            {
                                                isModifier: true,
                                                threadId: entityThread,
                                                value: 0,
                                            }
                                        );

                                        extraFunctionsMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            totalScenarioLength,
                                            "add"
                                        );
                                    }
                                }

                                const valuesMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    totalScenarioLength,
                                    {
                                        isModifier: true,
                                        threadId: entityThread,
                                    }
                                );

                                if (entity.data.growthType == "constant") {
                                    const growthRate = Number(
                                        entity.data.constantGrowthRate
                                    );
                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            value: growthRate,
                                        };
                                    }
                                } else if (
                                    entity.data.growthType == "dynamic"
                                ) {
                                    const simplifiedSegmentMap =
                                        getSimplifiedSegmentMap(
                                            "monthly",
                                            startDate,
                                            entity.endDate,
                                            totalScenarioLength,
                                            entity.data.growthSegments,
                                            entity.data.interpolate,
                                            entity.data.maxGrowthRate
                                        );

                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            value: simplifiedSegmentMap[date],
                                        };
                                    }
                                }

                                const functionsMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    totalScenarioLength,
                                    "compoundingCustomer"
                                );

                                for (const [date, val] of Object.entries(
                                    extraValuesMap
                                )) {
                                    if (!(date in valuesMap)) {
                                        valuesMap[date] = val;
                                    }
                                }

                                for (const [date, val] of Object.entries(
                                    extraFunctionsMap
                                )) {
                                    if (!(date in functionsMap)) {
                                        functionsMap[date] = val;
                                    }
                                }

                                const customerEntity = getRelevantEntities([
                                    entityId,
                                ])[entityId];
                                const defaultValue = entity.data.inheritCustomer
                                    ? customerEntity.data.customer
                                    : entity.data.startGrowth;

                                const override = {
                                    field: "value",
                                    defaultValue: defaultValue,
                                    cadence: "monthly",
                                    values: valuesMap,
                                    functions: functionsMap,
                                };

                                if (exportModifiers[entityId]) {
                                    exportModifiers[entityId].push(override);
                                } else {
                                    exportModifiers[entityId] = [override];
                                }
                            }

                            break;
                        }
                        case customerChurn2Object.constant(): {
                            const associatedEntityIds =
                                this.getEntityDependencies(
                                    entity.dependencies,
                                    "entity"
                                );

                            let entityThread = [];
                            // the every method breaks out of the loop if it encounters a falsy value
                            scenarioThreads.forEach((thread) => {
                                const { signature, nodes } = thread;
                                nodes.every((threadNode) => {
                                    if (threadNode.id === node.id) {
                                        entityThread.push(signature);
                                        // node exists in this thread so we exit the loop
                                        return false;
                                    }
                                    return true;
                                });
                            });

                            const scenarioLength = this.getScenarioLength();

                            const cadence = entity.cadence;

                            for (const entityId of associatedEntityIds) {
                                let startDate = entity.startDate;

                                // these are for appending to the values and functions map in case the startDate for those are later than for the customer entity, in which case it messes up the overmods in de3 since when there are modifiers overrides are run at the same time and wouldn't be run if modifiers for a date doesn't exist
                                let extraValuesMap = {};
                                let extraFunctionsMap = {};

                                if (entity.data.inheritStartDate) {
                                    const modEntityStartDate =
                                        this.getCustomerDateDependencies(
                                            entityId
                                        );

                                    startDate = modEntityStartDate;

                                    if (
                                        moment(modEntityStartDate).isBefore(
                                            moment(startDate),
                                            "months"
                                        )
                                    ) {
                                        extraValuesMap = createCadenceArray(
                                            "monthly",
                                            scenarioStartDate,
                                            startDate,
                                            scenarioLength,
                                            {
                                                isModifier: true,
                                                threadId: entityThread,
                                                value: 0,
                                            }
                                        );

                                        extraFunctionsMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            scenarioLength,
                                            "add"
                                        );
                                    }
                                }

                                const valuesMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    scenarioLength,
                                    {}
                                );

                                const valuesMap2 = {};
                                const functionsMap2 = {};

                                const allDateKeys = Object.keys(valuesMap);

                                if (entity.data.churnType == "constant") {
                                    const churnRate =
                                        entity.data.constantChurnPeriod ==
                                        "monthly"
                                            ? Number(
                                                  entity.data
                                                      .constantMonthlyChurnRate
                                              )
                                            : Number(
                                                  entity.data
                                                      .constantYearlyChurnRate
                                              );

                                    for (const date of allDateKeys) {
                                        valuesMap[date] = {
                                            isModifier: true,
                                            threadId: entityThread,
                                            dateToRecord: date,
                                            ...valuesMap[date],
                                        };

                                        const nextDate =
                                            getNextOverrideDateString(
                                                date,
                                                cadence
                                            );

                                        if (nextDate in valuesMap) {
                                            valuesMap[nextDate] = {
                                                prevDateToRecord: date,
                                            };
                                        }

                                        valuesMap2[nextDate] = {
                                            isModifier: true,
                                            threadId: entityThread,
                                            dateToSearch: date,
                                            churnRate: churnRate,
                                        };

                                        functionsMap2[nextDate] =
                                            "churnCustomer";
                                    }
                                } else if (entity.data.churnType == "dynamic") {
                                    const simplifiedSegmentMap =
                                        getSimplifiedSegmentMap(
                                            "monthly",
                                            startDate,
                                            entity.endDate,
                                            scenarioLength,
                                            entity.data.churnSegments,
                                            entity.data.interpolate,
                                            Number(entity.data.maxChurnRate),
                                            cadence
                                        );

                                    for (const date of allDateKeys) {
                                        valuesMap[date] = {
                                            isModifier: true,
                                            threadId: entityThread,
                                            dateToRecord: date,
                                            ...valuesMap[date],
                                        };

                                        const nextDate =
                                            getNextOverrideDateString(
                                                date,
                                                cadence
                                            );

                                        if (nextDate in valuesMap) {
                                            valuesMap[nextDate] = {
                                                prevDateToRecord: date,
                                            };
                                        }

                                        if (nextDate in simplifiedSegmentMap) {
                                            valuesMap2[nextDate] = {
                                                isModifier: true,
                                                threadId: entityThread,
                                                dateToSearch: date,
                                                churnRate:
                                                    simplifiedSegmentMap[
                                                        nextDate
                                                    ],
                                            };

                                            functionsMap2[nextDate] =
                                                "churnCustomer";
                                        }
                                    }
                                }

                                const functionsMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    scenarioLength,
                                    "churnCustomerRecord"
                                );

                                for (const [date, val] of Object.entries(
                                    extraValuesMap
                                )) {
                                    if (!(date in valuesMap)) {
                                        valuesMap[date] = val;
                                    }
                                }

                                for (const [date, val] of Object.entries(
                                    extraFunctionsMap
                                )) {
                                    if (!(date in functionsMap)) {
                                        functionsMap[date] = val;
                                    }
                                }

                                const customerEntity = getRelevantEntities([
                                    entityId,
                                ])[entityId];
                                const defaultValue = entity.data.inheritCustomer
                                    ? customerEntity.data.customer
                                    : entity.data.startChurn;

                                const override = {
                                    field: "value",
                                    defaultValue: defaultValue,
                                    cadence: "monthly",
                                    values: valuesMap,
                                    functions: functionsMap,
                                };

                                const override2 = {
                                    field: "value",
                                    defaultValue: defaultValue,
                                    cadence: "monthly",
                                    values: valuesMap2,
                                    functions: functionsMap2,
                                };

                                if (exportModifiers[entityId]) {
                                    exportModifiers[entityId].push(override);
                                    exportModifiers[entityId].push(override2);
                                } else {
                                    exportModifiers[entityId] = [
                                        override,
                                        override2,
                                    ];
                                }
                            }

                            break;
                        }
                        case customerGrowthObject.constant(): {
                            const customerAccountIds = [];
                            const grownPaths = [];

                            let customerEntitiesIds = entity.data?.customerIds;
                            if (customerEntitiesIds?.length == 0) {
                                const customerEvent = getEvent(
                                    entity.data?.customerEventId ?? ""
                                );
                                customerEvent
                                    ? (customerEntitiesIds =
                                          customerEvent.entities.map(
                                              (entity) => entity.id
                                          ))
                                    : [];
                            }

                            const customerEntities = Object.values(
                                getRelevantEntities(customerEntitiesIds)
                            );
                            let startDate = customerEntities[0]?.startDate;
                            for (const customerEntity of customerEntities) {
                                const dates = stringsToDates(
                                    startDate,
                                    customerEntity.startDate
                                );
                                if (dates[1] < dates[0]) {
                                    startDate = customerEntity.startDate;
                                }

                                customerAccountIds.push(
                                    customerEntity.data.parentAccountId
                                );
                                grownPaths.push(customerEntity.data.grownPath);
                            }

                            const data = {
                                id: entity.id,
                                type: customerGrowthObject.constant(),
                                startDate: entity?.data?.inheritStartDate
                                    ? startDate
                                    : entity?.startDate,
                                cadence: entity?.cadence ?? "monthly",
                                value:
                                    Number(entity.data.constantValue) / 100 ??
                                    0,
                                subType: entity?.data?.subType,
                                accountIds: customerAccountIds,
                                grownPaths: grownPaths,
                                maxRate:
                                    Number(entity?.data?.maxGrowth) / 100 ?? 0,
                                subAction:
                                    entity?.data?.subType === "constant"
                                        ? "value"
                                        : entity.data.interpolateSubType ===
                                          "Interpolate Growth Rate"
                                        ? "interpolate"
                                        : "step",
                                specificPercentages:
                                    entity.data.specificPercentages.reduce(
                                        function (map, obj) {
                                            let monthNumber = MMMMToMM(
                                                obj.month
                                            );
                                            let date = `${String(
                                                obj.year
                                            )}-${monthNumber}`;
                                            map[date] = Number(obj.value) / 100;
                                            return map;
                                        },
                                        {}
                                    ),
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case customerChurnObject.constant(): {
                            const customerAccountIds = [];
                            const grownAccountIds = [];
                            const churnedPaths = [];

                            let customerEntitiesIds = entity.data?.customerIds;
                            if (customerEntitiesIds?.length == 0) {
                                const customerEvent = getEvent(
                                    entity.data?.customerEventId
                                );
                                customerEvent
                                    ? (customerEntitiesIds =
                                          customerEvent.entities.map(
                                              (entity) => entity.id
                                          ))
                                    : [];
                            }

                            const customerEntities = Object.values(
                                getRelevantEntities(customerEntitiesIds)
                            );
                            let startDate = customerEntities[0]?.startDate;
                            for (const customerEntity of customerEntities) {
                                const dates = stringsToDates(
                                    startDate,
                                    customerEntity.startDate
                                );
                                if (dates[1] < dates[0]) {
                                    startDate = customerEntity.startDate;
                                }

                                customerAccountIds.push(
                                    customerEntity.data.parentAccountId
                                );
                                grownAccountIds.push(
                                    customerEntity.data.grownAccountId
                                );
                                churnedPaths.push(
                                    customerEntity.data.churnedPath
                                );
                            }

                            const delayChurn =
                                entity?.data?.delayChurn ?? false;

                            const data = {
                                id: entity.id,
                                type: customerChurnObject.constant(),
                                startDate: entity?.data?.inheritStartDate
                                    ? startDate
                                    : entity?.startDate,
                                cadence: delayChurn
                                    ? "monthly"
                                    : entity.cadence,
                                delayCadence: entity.cadence,
                                value:
                                    Number(entity.data.constantValue) / 100 ??
                                    0,
                                subType: entity?.data?.subType,
                                accountIds: customerAccountIds,
                                churnedPaths: churnedPaths,
                                grownAccountIds: grownAccountIds,
                                delayChurn: delayChurn,
                                maxRate:
                                    Number(entity?.data?.maxChurn) / 100 ?? 0,
                                subAction:
                                    entity?.data?.subType === "constant"
                                        ? "value"
                                        : entity.data.interpolateSubType ===
                                          "Interpolate Churn Rate"
                                        ? "interpolate"
                                        : "step",
                                specificPercentages:
                                    entity.data.specificPercentages.reduce(
                                        function (map, obj) {
                                            let monthNumber = MMMMToMM(
                                                obj.month
                                            );
                                            let date = `${String(
                                                obj.year
                                            )}-${monthNumber}`;
                                            map[date] = Number(obj.value) / 100;
                                            return map;
                                        },
                                        {}
                                    ),
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case customerTransferObject.constant(): {
                            const targetCustomerEntity = Object.values(
                                getRelevantEntities(
                                    entity?.data?.targetCustomerIds ?? []
                                )
                            )[0];
                            const targetTransInPath =
                                targetCustomerEntity?.data?.transInPath ?? [];

                            const sourceCustomerEntity = Object.values(
                                getRelevantEntities(
                                    entity?.data?.sourceCustomerIds ?? []
                                )
                            )[0];
                            const sourceCustomerAccountId =
                                sourceCustomerEntity?.data?.parentAccountId ??
                                "";
                            const sourceTransOutPath =
                                sourceCustomerEntity?.data?.transOutPath ?? [];

                            const modType = entity?.data?.modType;
                            const isWebsiteVisitor =
                                entity?.data?.targetType === "websiteVisitor";

                            const data = {
                                id: entity.id,
                                type: customerTransferObject.constant(),
                                startDate: entity?.startDate,
                                endDate: entity?.endDate,
                                cadence: isWebsiteVisitor
                                    ? "one-time"
                                    : entity?.cadence,
                                webVisCadence: entity?.cadence,
                                value:
                                    modType === "Percent change"
                                        ? Number(entity.data.value) / 100
                                        : Number(entity.data.value),
                                subType: entity?.data?.subType,
                                modType: modType,
                                targetType: entity?.data?.targetType ?? "",
                                sourceTransOutPath: sourceTransOutPath,
                                sourceAccount: sourceCustomerAccountId,
                                targetTransInPath: targetTransInPath,
                                maxRate:
                                    Number(entity?.data?.maxGrowth) / 100 ?? 0,
                                subAction:
                                    entity?.data?.subType === "constant"
                                        ? "value"
                                        : entity.data.interpolateSubType ===
                                          "Interpolate Growth Rate"
                                        ? "interpolate"
                                        : "step",
                                specificPercentages:
                                    entity.data.specificPercentages.reduce(
                                        function (map, obj) {
                                            let monthNumber = MMMMToMM(
                                                obj.month
                                            );
                                            let date = `${String(
                                                obj.year
                                            )}-${monthNumber}`;
                                            map[date] = Number(obj.value) / 100;
                                            return map;
                                        },
                                        {}
                                    ),
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case customerTransfer2Object.constant(): {
                            // could possibly add the option to select the "All" option or a group of customers but for now it's just an array of 1 entity
                            const associatedEntityIds =
                                this.getEntityDependencies(
                                    entity.dependencies,
                                    "sourceEntity"
                                );

                            const targetEntityId = this.getEntityDependencies(
                                entity.dependencies,
                                "targetCustomer"
                            )[0];

                            let entityThread = [];
                            // the every method breaks out of the loop if it encounters a falsy value
                            scenarioThreads.forEach((thread) => {
                                const { signature, nodes } = thread;
                                nodes.every((threadNode) => {
                                    if (threadNode.id === node.id) {
                                        entityThread.push(signature);
                                        // node exists in this thread so we exit the loop
                                        return false;
                                    }
                                    return true;
                                });
                            });

                            const scenarioLength = this.getScenarioLength();

                            let modType = "";
                            if (entity.data.modType == AddToValue) {
                                modType = "add";
                            } else if (entity.data.modType == PercentChange) {
                                modType = "percentChange";
                            }

                            for (const entityId of associatedEntityIds) {
                                let startDate = entity.startDate;

                                const sourceEntity = getRelevantEntities([
                                    entityId,
                                ])[entityId];

                                const type =
                                    sourceEntity.type ==
                                    customer2Object.constant()
                                        ? "customer"
                                        : "websiteVisitors";

                                // these are for appending to the values and functions map in case the startDate for those are later than for the customer entity, in which case it messes up the overmods in de3 since when there are modifiers overrides are run at the same time and wouldn't be run if modifiers for a date doesn't exist
                                let extraValuesMap = {};
                                let extraFunctionsMap = {};

                                if (!entity.data.customEffectPeriod) {
                                    const modEntityStartDate =
                                        sourceEntity.startDate;

                                    // growth starts one month after
                                    const newStartDate = moment(
                                        modEntityStartDate
                                    )
                                        .add(1, "M")
                                        .toISOString()
                                        .slice(0, 7)
                                        .concat("-01");

                                    startDate = newStartDate;

                                    if (
                                        moment(modEntityStartDate).isBefore(
                                            moment(startDate),
                                            "months"
                                        )
                                    ) {
                                        extraValuesMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            scenarioLength,
                                            {
                                                isModifier: true,
                                                threadId: entityThread,
                                                value: 0,
                                            }
                                        );

                                        extraFunctionsMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            scenarioLength,
                                            "add"
                                        );
                                    }
                                }

                                const valuesMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    scenarioLength,
                                    {
                                        isModifier: true,
                                        threadId: entityThread,
                                        modType: modType,
                                        source: entityId,
                                        target: targetEntityId,
                                        type: type,
                                    }
                                );

                                if (entity.data.transferType == "constant") {
                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            modType: data.modType,
                                            source: data.source,
                                            target: data.target,
                                            value: Number(
                                                entity.data.constantTransferRate
                                            ),
                                            type: data.type,
                                        };
                                    }
                                } else if (
                                    entity.data.transferType == "dynamic"
                                ) {
                                    const simplifiedSegmentMap =
                                        getSimplifiedSegmentMap(
                                            "monthly",
                                            startDate,
                                            entity.endDate,
                                            scenarioLength,
                                            entity.data.transferSegments,
                                            entity.data.interpolate,
                                            entity.data.maxTransferRate
                                        );

                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            modType: data.modType,
                                            source: data.source,
                                            target: data.target,
                                            value: simplifiedSegmentMap[date],
                                            type: data.type,
                                        };
                                    }
                                }

                                const functionsMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    scenarioLength,
                                    "flatTransfer"
                                );

                                for (const [date, val] of Object.entries(
                                    extraValuesMap
                                )) {
                                    if (!(date in valuesMap)) {
                                        valuesMap[date] = val;
                                    }
                                }

                                for (const [date, val] of Object.entries(
                                    extraFunctionsMap
                                )) {
                                    if (!(date in functionsMap)) {
                                        functionsMap[date] = val;
                                    }
                                }

                                const override = {
                                    field: "value",
                                    defaultValue: 0,
                                    cadence: "monthly",
                                    values: valuesMap,
                                    functions: functionsMap,
                                };

                                if (exportModifiers[entityId]) {
                                    exportModifiers[entityId].push(override);
                                } else {
                                    exportModifiers[entityId] = [override];
                                }
                            }

                            break;
                        }
                        case unitCostObject.constant(): {
                            const data = {
                                id: entity?.id,
                                type: entity?.type,
                                startDate: scenarioCalculationStart,
                                cadence: "daily",
                                value: Number(entity?.data?.value) ?? 0,
                                costOfGoodsSold:
                                    Number(entity?.data?.costOfGoodsSold) ?? 0,
                                isExpense:
                                    entity?.data?.unitCostType !== "Revenue" ??
                                    false,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case revenueObject.constant(): {
                            let startDate;
                            const accountIds = [];

                            const isSegment =
                                entity?.data?.revenueSource === "segment";

                            if (isSegment) {
                                const segmentEntity = Object.values(
                                    getRelevantEntities([
                                        entity?.data?.segmentEntityId,
                                    ])
                                )[0];
                                const customerEntity = Object.values(
                                    getRelevantEntities(
                                        segmentEntity?.data?.customerIds
                                    )
                                )[0];
                                startDate = customerEntity?.startDate;
                                accountIds.push(...entity?.data?.segmentIds);
                            } else {
                                const accountEntity = Object.values(
                                    getRelevantEntities(
                                        entity?.data?.customerIds
                                    )
                                )[0];
                                startDate = accountEntity?.startDate;
                                accountIds.push(
                                    accountEntity?.data?.parentAccountId
                                );
                            }

                            const unitCostEntity = Object.values(
                                getRelevantEntities(
                                    entity?.data?.unitCostIds ?? []
                                )
                            )[0];
                            // const sendCOGS = unitCostEntity?.data?.includeCostOfGoodsSold && unitCostEntity?.data?.unitCostType === "Revenue"

                            const cashPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];
                            const cogsPath = [
                                "7faf0285-78ca-411b-b875-d900929d7c94",
                                "63d6884b-7792-49b7-8fca-a0d1c0d025a2",
                            ];
                            // const arpuPath = ["2b5b218c-345b-48f9-8b4f-2992978acfd5", "43863d4a-b569-44c8-a6d6-6e85e5e8b0c6"] //Add path from kpi -> profit if we want profit context in kpi account

                            const delayRevenue =
                                entity?.data?.delayRevenue ?? false;

                            let endDate = undefined;

                            if (entity?.data?.overrideInheritedDate) {
                                startDate = entity.startDate ?? startDate;
                                endDate = entity.endDate;
                            }

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: startDate,
                                endDate: endDate,
                                cadence: delayRevenue
                                    ? "monthly"
                                    : unitCostEntity?.cadence,
                                delayCadence: unitCostEntity?.cadence,
                                accountId: entity.data?.parentAccountId,
                                unitCostId: unitCostEntity?.id ?? "",
                                unitCostValue: 0,
                                unitCostCOGS: 0,
                                customerAccountIds: accountIds,
                                accountIdsPaths: [
                                    ...(entity?.data?.accountIdsPaths ?? []),
                                    cashPath,
                                    cogsPath,
                                ],
                                delayRevenue: delayRevenue,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case instanceObject.constant(): {
                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: entity.cadence,
                                startDate: entity.startDate,
                                endDate: entity.endDate,
                                value: Number(
                                    entity.data.value //instance amount
                                ),
                                targetEventId: entity.data?.selectedEvent,
                                targetEntity: entity.data.selectedEntity,
                                isActive: false,
                            };
                            exportEntities[entity.id] = data;
                            const targetEntities =
                                entity.data.instanceType == "recordInstance"
                                    ? entity.data.selectedEntity
                                    : entity.data.selectedGroupEntity;
                            //instancing Groups
                            exportInstances = formatData(
                                entity,
                                exportInstances,
                                exportEntities,
                                targetEntities
                            );

                            if (entity.data.isBypassed) {
                                toDelete.push(targetEntities.entityIds[0]);
                                if (
                                    entity.data.instanceType == "groupInstance"
                                ) {
                                    if (entity.data.isProject) {
                                        toDelete = toDelete.concat(
                                            formatProjectInstance(entity).idList
                                        );
                                    } else {
                                        toDelete = toDelete.concat(
                                            formatGroupInstance(entity)
                                        );
                                    }
                                }
                            }
                            break;
                        }
                        case kpiObject.constant(): {
                            if (
                                !entity.data?.arpu &&
                                !entity.data?.lifetimeValue &&
                                !entity.data?.profitMargin
                            ) {
                                break;
                            }

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: "monthly",
                                arpu: entity.data?.arpu,
                                ltv: entity.data?.lifetimeValue,
                                profitMargin: entity.data?.profitMargin,
                                accountIdsPaths:
                                    entity.data.accountIdsPaths ?? [],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case cacObject.constant(): {
                            const customerEntity = Object.values(
                                getRelevantEntities(
                                    entity?.data?.customerIds ?? []
                                )
                            )[0];
                            const customerStartDate =
                                customerEntity?.startDate ?? "";
                            const customerAccountId =
                                customerEntity?.data?.parentAccountId ?? "";

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                customerId: customerAccountId,
                                subAction:
                                    entity.data.subType === "constant"
                                        ? "value"
                                        : entity.data.interpolateSubType ===
                                          "Interpolate Costs"
                                        ? "interpolate"
                                        : "step",
                                value: entity.data.constantValue
                                    ? Number(entity.data.constantValue)
                                    : 0,
                                specificPercentages:
                                    entity.data.specificPercentages.reduce(
                                        function (map, obj) {
                                            let monthNumber = MMMMToMM(
                                                obj.month
                                            );
                                            let date = `${String(
                                                obj.year
                                            )}-${monthNumber}`;
                                            map[date] = Number(obj.value);
                                            return map;
                                        },
                                        {}
                                    ),
                                cadence: "monthly",
                                startDate:
                                    entity.data.inheritStartDate &&
                                    customerStartDate
                                        ? customerStartDate
                                        : entity.data.startDate,
                                accountIdsPaths:
                                    entity.data.accountIdsPaths ?? [],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case houseObject.constant(): {
                            // this is to support existing scenarios that didn't have
                            // address validation
                            if (!entity.data.province) {
                                entity.data.province =
                                    extractProvinceFromAddress(
                                        entity.data.address
                                    );
                            }
                            if (!entity.data.city) {
                                entity.data.city = extractCityFromAddress(
                                    entity.data.address
                                );
                            }
                            // let metadata = node.metadata;
                            let data = {
                                id: entity.id,
                                type: entity.type,
                                value: Number(entity.data.price),
                                primaryResidence:
                                    entity.data.primaryResidence === "yes",
                                newlyBuilt:
                                    entity.data.newlyBuiltHomeTrue === "yes",
                                inflationRate: 0,
                                inflation: loadedScenario.inflation
                                    ? true
                                    : false,
                                startDate: entity.data["first payment"],
                                cadence: "monthly",
                                country: entity.data.address.split(", ")[3],
                                province: entity.data.province,
                                city: entity.data.city,
                            };
                            if (entity.data.applyAppreciation) {
                                data.inflationRate = Number(
                                    entity.data.appreciationRate / 100
                                );
                            }

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case employeeObject.constant(): {
                            const cashPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];

                            const accountIdsPaths = [
                                entity.data.accountIds ?? [],
                                cashPath,
                            ];
                            switch (entity.data.expenseType) {
                                case "Cost of Labor":
                                    accountIdsPaths.push([
                                        "7faf0285-78ca-411b-b875-d900929d7c94",
                                        "63d6884b-7792-49b7-8fca-a0d1c0d025a2",
                                        "7541eeb3-9a6a-46e5-b0b5-b616134e87ba",
                                    ]);
                                    break;
                                case "Employment Expenses":
                                    accountIdsPaths.push([
                                        "7faf0285-78ca-411b-b875-d900929d7c94",
                                        "0e1da99d-a3ce-42b9-b5d4-ac252f350cfb",
                                        "d10bcaa7-f640-4f1b-bb40-3aebc0c088d3",
                                    ]);
                                    break;
                                default:
                            }

                            const accountsAndLedgers =
                                store.getState().allAccountLedgers
                                    .ledgersMetadata;

                            const employeeAccount =
                                entity.data.accountIds[
                                    entity.data.accountIds.length - 1
                                ];

                            const capacityAccountId =
                                accountsAndLedgers[employeeAccount]
                                    .shadowIds[1];

                            const capacityPaths =
                                recurseToTopAccount(capacityAccountId);

                            // This should be suspicious, but is ok here, since
                            // I also want the capacity accounts to
                            // get the same value added onto them as the main employee
                            // account itself. This also adds the capacity account
                            // onto the ledgersMap.
                            entity.data.accountIds =
                                entity.data.accountIds.concat(capacityPaths);

                            accountIdsPaths.push(capacityPaths);

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: entity.cadence,
                                expenseType: entity.data.expenseType,
                                inflation: entity.data.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                payType: entity.data.payType,
                                averageHours: Number(entity.data.averageHours),
                                value:
                                    entity.data.payType == "hourly"
                                        ? Number(entity.data.rate)
                                        : entity.data.salary
                                        ? Number(entity.data.salary)
                                        : 0,
                                noEmployees:
                                    entity.data.employeeState == "placeholder"
                                        ? Number(entity.data.noEmployees)
                                        : 1,
                                startDate: entity.startDate,
                                endDate: entity.endDate,
                                address: entity.data.address,
                                province: entity.data.province || "",
                                city: entity.data.city,
                                includeOvertime:
                                    entity.data.payType === "hourly"
                                        ? entity.data.overtime
                                        : false,
                                includePension: entity.data.includePension,
                                includeEI: entity.data.includeEI,
                                accountIds: entity.data.accountIds,
                                accountIdsPaths: accountIdsPaths,
                                capacityPaths: capacityPaths,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }

                        case mortgageInsuranceObject.constant(): {
                            const modsCreated = [];

                            if (
                                entity.metadata.paymentMethod ===
                                "Add to Mortgage"
                            ) {
                                const modifiedMortgageIds =
                                    this.getMortgageInsuranceMortgages(
                                        entity,
                                        scenarioThreads
                                    );
                                const modId = uuid.v4();
                                const mod = {
                                    id: modId,
                                    nodeType: Modifier,
                                    startDate: entity.start,
                                    action: "set",
                                    keyToModify: "principal_remaining",
                                    idsToModify: modifiedMortgageIds,
                                    typesToModify: ["Mortgage"],
                                    value:
                                        entity.metadata
                                            .lastChangedPremiumProperty ===
                                        "percent"
                                            ? entity.metadata.premiumPercent
                                            : entity.metadata.premiumAmount,
                                    misc: {
                                        lastChangedPremiumProperty:
                                            entity.metadata
                                                .lastChangedPremiumProperty,
                                        useAutoValue:
                                            entity.metadata.useAutoValue,
                                    },
                                    cadence: "one-time",
                                };
                                modsCreated.push(modId);
                                exportEntities[modId] = mod;
                            }

                            const metadata = {
                                id: entity.id,
                                nodeType: mortgageInsuranceObject.constant(),
                                cadence: "monthly",
                                lastChangedPremiumProperty:
                                    entity.metadata.lastChangedPremiumProperty,
                                premiumPercent: Number(
                                    entity.metadata.premiumPercent
                                ),
                                premiumAmount: entity.metadata.premiumAmount,
                                taxRate: entity.metadata.taxRate,
                                taxAmount: entity.metadata.taxAmount,
                                paymentMethod: entity.metadata.paymentMethod,
                                modsCreated: modsCreated,
                                useAutoValue: entity.metadata.useAutoValue,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                            };

                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case accountModifierObject.constant(): {
                            let value = 0;
                            if (entity?.data?.action === "constrain") {
                                value = {
                                    minValue:
                                        Number(entity?.data?.minValue) ||
                                        undefined,
                                    maxValue:
                                        Number(entity?.data?.maxValue) ||
                                        undefined,
                                };
                            } else if (entity?.data?.action === "override") {
                                value =
                                    entity?.data?.modsCreated[0]?.value ?? {};
                            } else {
                                value = Number(entity?.data?.value);
                            }

                            const data = {
                                id: entity.id,
                                cadence: entity?.cadence,
                                startDate: entity?.startDate ?? "",
                                endDate: entity?.endDate ?? "",
                                nodeType: entity.data.entityType,
                                value: value,
                                action: entity.data.action,
                                accountIds: entity.data.accountIds,
                                accountIdsPaths: [entity.data.accountIds],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case modifierObject.constant(): {
                            const modifierNodeEntities = [];

                            let entitiesIds = entity.data?.selectedIds;
                            if (entitiesIds?.length === 0) {
                                const event = getEvent(
                                    entity.data?.selectedEventId ?? ""
                                );
                                event
                                    ? (entitiesIds = event.entities.map(
                                          (entity) => entity.id
                                      ))
                                    : [];
                            }

                            const topLevelEntities = Object.values(
                                getRelevantEntities(entitiesIds)
                            );

                            const entities = [];

                            for (const topLevelEntity of topLevelEntities) {
                                if (
                                    topLevelEntity.type ===
                                    groupObject.constant()
                                ) {
                                    const groupMode =
                                        topLevelEntity.data.groupMode;

                                    if (groupMode === "events") {
                                        const events =
                                            topLevelEntity.data
                                                .selectedEvents ?? [];

                                        for (const includedEventId of events) {
                                            const includedEvent = getEvent(
                                                includedEventId,
                                                true
                                            );

                                            const includedEntities =
                                                Object.values(
                                                    getRelevantEntities(
                                                        includedEvent.entities
                                                    )
                                                );

                                            for (const includedEntity of includedEntities) {
                                                entities.push(includedEntity);
                                            }
                                        }
                                    } else if (groupMode === "entities") {
                                        for (const includedEntity of topLevelEntity
                                            .data.selectedEntities ?? []) {
                                            const entityMod =
                                                getRelevantEntities([
                                                    includedEntity,
                                                ])[includedEntity];
                                            entities.push(entityMod);
                                        }
                                    } else {
                                        console.log(
                                            "Bad group mode",
                                            groupMode
                                        );
                                    }
                                } else {
                                    entities.push(topLevelEntity);
                                }
                            }

                            for (const targetEntity of entities) {
                                // If the entity start day is after the modifier startDate, and 29 or higher then we set the start day of the
                                // modifiers to be the last day of the shortest month that sits b/w the modifier startDate and entity startDate
                                // given that the endDate
                                const customStartDate =
                                    getModifierCustomStartDate(
                                        entity?.startDate ||
                                            scenarioCalculationStart,
                                        targetEntity?.startDate ||
                                            scenarioCalculationStart
                                    );

                                const entityMods =
                                    targetEntity?.data?.modsCreated ?? [];

                                // If a modified entity is overriden, that override takes effect every cadence period.
                                // For example, if you overwrite a field to be some value, it sets that field to that value
                                // every cadence period. This is not great for one-time modifications, since they only generate
                                // modification context at the start of its effect period. This means that the override will
                                // then write over that modification context. This bit of code scans through the entities for
                                // an overriden field. If a field does exist, then it changes the modifier to be recurring, so
                                // that its effect gets "refreshed" after every override takes effect.
                                let overrideExists = false;
                                if (entityMods?.length > 0) {
                                    entityMods?.map((entityModification) => {
                                        if (
                                            entityModification?.action ===
                                            "override"
                                        )
                                            overrideExists = true;
                                    });
                                }

                                let modifiedCadence = "";
                                modifiedCadence = entity.data.cadenceType;
                                if (
                                    overrideExists &&
                                    entity.data.cadenceType === "One-time"
                                )
                                    modifiedCadence = "Recurring";

                                const feName =
                                    modifierEntityMap[entity?.data?.entityType][
                                        entity?.data?.property.split(" - ")?.[0]
                                    ]?.feName ?? "";

                                let targetStartDate = "";
                                if (targetEntity?.startDate) {
                                    const targetMoment = moment(
                                        targetEntity?.startDate
                                    );
                                    targetStartDate = targetMoment
                                        .set("date", 1)
                                        .format("YYYY-MM-DD");
                                }

                                const entityIds = [];
                                entityIds.push(targetEntity.id);

                                const data = {
                                    id: `${entity.id} - ${targetEntity.id}`,
                                    cadence: targetEntity?.cadence ?? "monthly",
                                    startDate: entity?.data?.customEffectPeriod
                                        ? customStartDate
                                        : targetStartDate,
                                    endDate: entity?.endDate ?? "",
                                    nodeType: entity.data.entityType,
                                    feName: feName,
                                    keyToModify: entity.data.deName,
                                    value:
                                        entity.data.modifierType === "date" &&
                                        entity.data.function === "Set"
                                            ? entity.data.value
                                            : Number(entity.data.value),
                                    action: entity.data.function,
                                    entityId: entityIds,
                                    revert: entity.data.revertValue,
                                    cadenceType: modifiedCadence,
                                    selectedCadenceType:
                                        entity.data.cadenceType,
                                };
                                modifierNodeEntities.push({
                                    id: data.id,
                                    active: true,
                                });
                                exportEntities[data.id] = data;
                            }

                            if (modifierNodes[node.id]?.length) {
                                modifierNodes[node.id].push(
                                    ...modifierNodeEntities
                                );
                            } else {
                                modifierNodes[node.id] = modifierNodeEntities;
                            }

                            break;
                        }
                        case modifier2Object.constant(): {
                            let entityThread = [];
                            // the every method breaks out of the loop if it encounters a falsy value
                            scenarioThreads.forEach((thread) => {
                                const { signature, nodes } = thread;
                                nodes.every((threadNode) => {
                                    if (threadNode.id === node.id) {
                                        entityThread.push(signature);
                                        // node exists in this thread so we exit the loop
                                        return false;
                                    }
                                    return true;
                                });
                            });

                            if (entity.data.mode === AnyNode) {
                                let modifier = getEvent(node.id);
                                let root = this.props.manager.getRootNode();

                                if (root) {
                                    let updatedProperties = {};
                                    recurseToModifier(
                                        root,
                                        modifier,
                                        {},
                                        updatedProperties
                                    );
                                    getBaselineProperties(
                                        updatedProperties,
                                        this.props.manager
                                    );

                                    let property = entity.data.propertySelected;
                                    if (updatedProperties[property]) {
                                        // These are entity Ids, not event Ids
                                        const associatedIds = Array.from(
                                            updatedProperties[property].nodeIds
                                        );
                                        entity.data.associatedIds =
                                            associatedIds;
                                        entity.data.decisionEngineName =
                                            updatedProperties[property].deName;
                                    }
                                }
                            } else if (entity.data.mode == SpecificNode) {
                                const relevantEntities =
                                    this.getEntityDependencies(
                                        entity.dependencies,
                                        "entity"
                                    );
                                entity.data.associatedIds = relevantEntities;
                            }

                            for (const entityId of entity.data.associatedIds) {
                                const modEntityData =
                                    Object.values(
                                        getRelevantEntities([entityId])
                                    )[0] ?? {};

                                // Customer 2
                                if (
                                    modEntityData.type ==
                                    customer2Object.constant()
                                ) {
                                    const customer2Override =
                                        getModifierForCustomer2(
                                            entity.data.subMode,
                                            entity.data.value,
                                            exportOverrides[modEntityData.id]
                                        );

                                    if (customer2Override) {
                                        // yes we are replacing the override, having already stacked on the previous value
                                        exportOverrides[modEntityData.id] = [
                                            customer2Override,
                                        ];
                                    }

                                    continue;
                                }

                                let modEntityStartDate =
                                    modEntityData.startDate;
                                let modEntityCadence = modEntityData.cadence;

                                let cadenceArrayStartDate =
                                    entity.startDate || modEntityStartDate;

                                if (entity.startDate && modEntityStartDate) {
                                    if (
                                        moment(entity.startDate).isBefore(
                                            modEntityStartDate,
                                            "month"
                                        )
                                    ) {
                                        cadenceArrayStartDate = moment(
                                            modEntityStartDate.slice(0, 8)
                                        ).format("YYYY-MM-DD");
                                    }
                                }

                                const valuesMap = createCadenceArray(
                                    modEntityCadence,
                                    cadenceArrayStartDate,
                                    entity.endDate,
                                    this.getScenarioLength(),
                                    {
                                        value: isNaN(entity.data.value)
                                            ? entity.data.value
                                            : Number(entity.data.value),
                                        isModifier: true,
                                        // TODO: move this into main data structure for modifiers
                                        threadId: entityThread,
                                    }
                                );
                                const functionsMap = createCadenceArray(
                                    modEntityCadence,
                                    entity.startDate || modEntityStartDate,
                                    entity.endDate,
                                    this.getScenarioLength(),
                                    entity.data.subMode === PercentChange
                                        ? "percentChange"
                                        : entity.data.subMode === AddToValue
                                        ? "add"
                                        : entity.data.subMode === ValueReplacer
                                        ? "replace"
                                        : "compounding"
                                );

                                const override = {
                                    field: entity.data.decisionEngineName,
                                    defaultValue: getDefaultValue(
                                        modEntityData,
                                        entity.data.frontEndName
                                    ),
                                    cadence: modEntityCadence,
                                    values: valuesMap,
                                    functions: functionsMap,
                                };

                                if (exportModifiers[modEntityData.id]) {
                                    exportModifiers[modEntityData.id].push(
                                        override
                                    );
                                } else {
                                    exportModifiers[modEntityData.id] = [
                                        override,
                                    ];
                                }
                            }
                            break;
                        }
                        case pensionObject.constant(): {
                            var today = new Date();
                            var dd = String(today.getDate() + 1).padStart(
                                2,
                                "0"
                            );
                            var mm = String(today.getMonth() + 1).padStart(
                                2,
                                "0"
                            ); //January is 0!
                            var yyyy = today.getFullYear();
                            today = yyyy + "-" + mm + "-" + dd;

                            const metadata = {
                                id: entity.id,
                                startDate: today,
                                cadence: "monthly",
                                nodeType: pensionObject.constant(),
                                inflationRate: Number(loadedScenario.inflation),
                                startAge: entity.metadata["startAge"]
                                    ? Number(entity.metadata["startAge"])
                                    : 18,
                                pensionCalculationType:
                                    entity.metadata.pensionCalculationType,
                                contributionType:
                                    entity.metadata.contributionType,
                                pensionableRate:
                                    Number(entity.metadata.pensionableRate) /
                                    100,
                                cppRate: entity.metadata["cppRate"]
                                    ? Number(entity.metadata["cppRate"]) / 100
                                    : 0,
                                qppRate: entity.metadata["qppRate"]
                                    ? Number(entity.metadata["qppRate"]) / 100
                                    : 0,
                                actualsData: entity.metadata.actualsData,
                                showSettings: entity.metadata.showSettings,
                                showSettingsData:
                                    entity.metadata.showSettingsData,
                                retirementDate: moment(
                                    (
                                        Number(entity.metadata.birthYear) + 150
                                    ).toString() + "-01-01",
                                    "YYYY-MM-DD"
                                ).format("YYYY-MM-DD"),
                                personID: entity.metadata.personId,
                                country: entity.metadata.country,
                                state: entity.metadata.state,
                                birthYear: entity.metadata.birthYear,
                                birthMonth: parseInt(
                                    MMMMToMM(entity.metadata.birthMonth)
                                ),
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case lumpSumPaymentObject.constant(): {
                            let metadata = _.cloneDeep(entity.metadata);

                            if (metadata.type)
                                metadata = this.renameObjectKey(
                                    metadata,
                                    "type",
                                    "nodeType"
                                );

                            metadata["value"] = parseInt(metadata["value"]);

                            metadata["id"] = entity.id;
                            metadata["nodeTypes"] = null;
                            metadata["nodeType"] = Modifier;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case renewalRefinanceObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                mortgageId: entity.metadata.mortgage,
                                nodeType: "Renewal Refinance",
                                decisionEngineName: "renewalRefinance",
                                term: entity.metadata.term,
                                startDate:
                                    entity.metadata.startOption === AutoStart
                                        ? AutoStart
                                        : entity.metadata.startDate,
                                rate:
                                    entity.metadata.rate === DefaultSetting
                                        ? null
                                        : Number(entity.metadata.rate),
                                cadence:
                                    entity.metadata.cadence === DefaultSetting
                                        ? DefaultSetting
                                        : entity.metadata.cadence,
                                amortizationPeriod:
                                    entity.metadata.amortizationPeriod ===
                                    DefaultSetting
                                        ? null
                                        : parseInt(
                                              entity.metadata.amortizationPeriod
                                          ),
                                selectedForm: entity.metadata.selectedForm,
                                percentWithdrawn: Number(
                                    entity.metadata.percentWithdrawn
                                ),
                                amountWithdrawn:
                                    entity.metadata.amountWithdrawn,
                                withdrawalType: entity.metadata.withdrawalType,
                                refinancingFees: Number(
                                    entity.metadata.refinancingFees
                                ),
                                refinanceType: entity.metadata.refinanceType,
                                customValue: Number(
                                    entity.metadata.customValue
                                ),
                                inflation: entity.metadata.inflation,
                                inflationRate: entity.metadata.inflationRate,
                                value: entity.metadata.value
                                    ? entity.metadata.value
                                    : 0,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case retirementObject.constant(): {
                            const {
                                pensionIds,
                                incomeIds,
                                rrspIds,
                                investmentIds,
                            } = this.getRetirementDependencies(
                                entity,
                                nodesWithBaseline
                            );

                            const pensionModId = uuid.v4();
                            const pensionMod = {
                                id: pensionModId,
                                keyToModify: "retirementDate",
                                nodeType: Modifier,
                                startDate: entity.metadata.start,
                                action: "set",
                                cadence: "one-time",
                                value: entity.metadata.start,
                                typesToModify: ["Pension"],
                                idsToModify: pensionIds,
                            };
                            const incomeModId = uuid.v4();
                            const incomeMod = {
                                id: incomeModId,
                                keyToModify: "value",
                                nodeType: Modifier,
                                startDate: entity.metadata.start,
                                action: "retirement",
                                value: 0,
                                idsToModify: incomeIds,
                                typesToModify: ["Income"],
                                cadence: "one-time",
                            };
                            const investmentModId = uuid.v4();
                            const investmentMod = {
                                id: investmentModId,
                                keyToModify: "endDate",
                                nodeType: Modifier,
                                startDate: entity.metadata.start,
                                action: "set",
                                value: entity.metadata.start,
                                idsToModify: investmentIds,
                                typesToModify: ["Investment"],
                                cadence: "one-time",
                            };
                            const rrspModId = uuid.v4();
                            const rrspMod = {
                                id: rrspModId,
                                keyToModify: "endDate",
                                nodeType: Modifier,
                                startDate: entity.metadata.start,
                                action: "set",
                                value: entity.metadata.start,
                                idsToModify: rrspIds,
                                typesToModify: ["RRSP"],
                                cadence: "one-time",
                            };
                            const metadata = {
                                id: entity.id,
                                nodeType: retirementObject.constant(),
                                startDate: entity.metadata.startDate,
                                modsCreated: [
                                    incomeModId,
                                    pensionModId,
                                    investmentModId,
                                    rrspModId,
                                ],
                            };
                            exportEntities[investmentModId] = investmentMod;
                            exportEntities[rrspModId] = rrspMod;
                            exportEntities[incomeModId] = incomeMod;
                            exportEntities[pensionModId] = pensionMod;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case graduationObject.constant(): {
                            // dependencies should change in the future
                            const { hourlyIncomeIds, studentLoanIds } =
                                this.getGraduationDependencies(
                                    entity,
                                    nodesWithBaseline
                                );

                            var today = new Date();
                            var dd = String(today.getDate() + 1).padStart(
                                2,
                                "0"
                            );
                            var mm = String(today.getMonth() + 1).padStart(
                                2,
                                "0"
                            ); //January is 0!
                            var yyyy = today.getFullYear();
                            today = yyyy + "-" + mm + "-" + dd;

                            const studentLoanModId = uuid.v4();
                            const studentLoanMod = {
                                id: studentLoanModId,
                                keyToModify: "graduationDate",
                                typesToModify: ["Student Loan"],
                                nodeType: Modifier,
                                startDate: today,
                                action: "graduation",
                                value: entity.metadata.start,
                                cadence: "one-time",
                                idsToModify: studentLoanIds,
                            };
                            const incomeModId = uuid.v4();
                            const incomeMod = {
                                id: incomeModId,
                                keyToModify: "endDate",
                                typesToModify: ["Part Time Job"],
                                nodeType: Modifier,
                                startDate: today,
                                action: "graduation",
                                value: entity.metadata.start,
                                cadence: "one-time",
                                idsToModify: hourlyIncomeIds,
                            };
                            const metadata = {
                                id: entity.id,
                                nodeType: graduationObject.constant(),
                                startDate: entity.metadata.startDate,
                                modsCreated: [incomeModId, studentLoanModId],
                            };
                            exportEntities[incomeModId] = incomeMod;
                            exportEntities[studentLoanModId] = studentLoanMod;
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case rrspObject.constant(): {
                            let start = entity.metadata.start;
                            if (entity.metadata.cadence === "one-time") {
                                var today = new Date();
                                var dd = String(today.getDate() + 1).padStart(
                                    2,
                                    "0"
                                );
                                var mm = String(today.getMonth() + 1).padStart(
                                    2,
                                    "0"
                                ); //January is 0!
                                var yyyy = today.getFullYear();
                                today = yyyy + "-" + mm + "-" + dd;
                                start = today;
                            }
                            const metadata = {
                                id: entity.id,
                                nodeType: rrspObject.constant(),
                                subType: rrspObject.constant(),
                                startDate: start,
                                endDate: entity.metadata.end,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                owner: entity.metadata.personId,
                                value: entity.metadata.value,
                                cadence: entity.metadata.cadence,
                                initialContribution:
                                    entity.metadata.initialValue,
                                appreciationRate:
                                    entity.metadata.interestRate !== null
                                        ? Number(entity.metadata.interestRate) /
                                          100
                                        : 0.0,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case investmentObject.constant(): {
                            let start = entity.metadata.start;
                            if (entity.metadata.cadence === "one-time") {
                                var today = new Date();
                                var dd = String(today.getDate() + 1).padStart(
                                    2,
                                    "0"
                                );
                                var mm = String(today.getMonth() + 1).padStart(
                                    2,
                                    "0"
                                ); //January is 0!
                                var yyyy = today.getFullYear();
                                today = yyyy + "-" + mm + "-" + dd;
                                start = today;
                            }
                            const metadata = {
                                id: entity.id,
                                nodeType: "Investment",
                                subType: entity.metadata.subType,
                                startDate: start,
                                endDate: entity.metadata.end,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                personId: entity.metadata.personId,
                                value: entity.metadata.value,
                                cadence: entity.metadata.cadence,
                                initialContribution:
                                    entity.metadata.initialValue,
                                appreciationRate:
                                    entity.metadata.interestRate !== null
                                        ? Number(entity.metadata.interestRate) /
                                          100
                                        : 0.0,
                                owner: entity.metadata.personId,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case capitalGainsObject.constant(): {
                            // TODO needs work, not sure
                            const incomeModId = uuid.v4();
                            const incomeMod = {
                                id: incomeModId,
                                keyToModify: "value",
                                nodeType: Modifier,
                                cadence: "one-time",
                                startDate: entity.metadata.startDate,
                                value: entity.metadata.value,
                                action: "set",
                                typesToModify: [incomeObject.constant()],
                            };
                            exportEntities[incomeModId] = incomeMod;
                            const metadata = {
                                id: entity.id,
                                nodeType: capitalGainsObject.constant(),
                                startDate: entity.metadata.startDate,
                                owner: entity.metadata.personId,
                                province: entity.metadata.province,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                cadence: "one-time",
                                modsCreated: [incomeModId],
                            };

                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case businessObject.constant():
                        case meObject.constant():
                        case personObject.constant(): {
                            let data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                name: entity.name,
                                country: entity.data.country,
                                state: entity.data.state,
                                cadence: "one-time",
                            };
                            if (entity.type !== businessObject.constant()) {
                                data = {
                                    ...data,
                                    hasDisability: entity.data.hasDisability,
                                    birthMonth: entity.data.birthMonth,
                                    birthYear: entity.data.birthYear,
                                    relationship: entity.data.relationship,
                                };
                            }
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case closingCostsObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: closingCostsObject.constant(),
                                name: entity.metadata.name,
                                value: entity.metadata.value,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                cadence: entity.metadata.cadence,
                                includePropertyEvalFee:
                                    entity.metadata.includePropertyEvalFee,
                                propertyEvaluationFee:
                                    entity.metadata.propertyEvaluationFee,
                                includeLandSurveyFee:
                                    entity.metadata.includeLandSurveyFee,
                                landSurveyFee: entity.metadata.landSurveyFee,
                                includeTitleInsurance:
                                    entity.metadata.includeTitleInsurance,
                                titleInsurance: entity.metadata.titleInsurance,
                                includeLegalCosts:
                                    entity.metadata.includeLegalCosts,
                                legalCosts: entity.metadata.legalCosts,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case shortTermRentalObject.constant(): {
                            const metadata = {
                                id: entity.id,
                                nodeType: shortTermRentalObject.constant(),
                                name: entity.metadata.name,
                                startDate: entity.metadata.startDate,
                                endDate: entity.metadata.endDate,
                                inflation: entity.metadata.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                cadence: entity.metadata.cadence,
                                upfrontCosts: Number(
                                    entity.metadata.upfrontCosts
                                ),
                                monthlyCosts: Number(
                                    entity.metadata.monthlyCosts
                                ),
                                averageMonthlyNights: Number(
                                    entity.metadata.averageMonthlyNights
                                ),
                                dailyRate: Number(entity.metadata.dailyRate),
                                daysPerStay: Number(
                                    entity.metadata.daysPerStay
                                ),
                                perStayCosts: Number(
                                    entity.metadata.perStayCosts
                                ),
                                otherFeesRate: Number(
                                    entity.metadata.otherFeesRate
                                ),
                                hotelTaxRate: Number(
                                    entity.metadata.hotelTaxRate
                                ),
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case condoFeeObject.constant(): {
                            const isOngoing =
                                entity.metadata.expenseType === "ongoing";
                            const startDate = isOngoing
                                ? entity.metadata.startDate
                                : entity.metadata.start;
                            const endDate = isOngoing
                                ? entity.metadata.endDate
                                : null;
                            const metadata = {
                                id: entity.id,
                                nodeType: "Condo Fee",
                                cadence: entity.metadata.cadence,
                                startDate: startDate,
                                endDate: endDate,
                                expenseType: entity.metadata.expenseType,
                                numPayments: entity.metadata.numPayments,
                                rating: entity.metadata.rating,
                                name: entity.metadata.name,
                                value: isOngoing
                                    ? Number(entity.metadata.cost)
                                    : entity.metadata.value,
                                inflation: isOngoing
                                    ? entity.metadata.applyInflation
                                    : //   node.metadata.inflation // legacy 2021-02-01
                                      false,
                                inflationRate: valueDoesNotExist(
                                    entity.metadata.inflationRate // legacy 2021-02-01
                                )
                                    ? Number(loadedScenario.inflation)
                                    : entity.metadata.inflationRate / 100,
                                owner: entity.metadata.owner,
                            };
                            exportEntities[entity.id] = metadata;
                            break;
                        }
                        case contractObject.constant(): {
                            const cashPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];

                            const accountIdsPaths = entity?.data?.accountIds
                                ? [entity.data.accountIds, cashPath]
                                : entity.data.accountIdsPaths ?? [];

                            const data = {
                                version: entity.version,
                                id: entity.id,
                                type: entity.type,
                                inflation: entity.data.inflation,
                                inflationRate: Number(loadedScenario.inflation),
                                cadence: "one-time",
                                owner: entity.data?.businessIds[0],
                                startDate: entity.startDate,
                                scenarioStartDate,
                                value: Number(entity.data.income),
                                rating: entity.data.rating,
                                milestones:
                                    entity.data.specificPercentages.reduce(
                                        function (arr, obj) {
                                            arr.push([
                                                Number(
                                                    calcDateDiff(
                                                        entity.startDate,
                                                        obj.date
                                                    )
                                                ) +
                                                    Number(
                                                        obj.net.split(" ")[0]
                                                    ),
                                                Number(obj.value),
                                            ]);
                                            return arr;
                                        },
                                        [
                                            [
                                                Number(
                                                    entity.data.netInitial.split(
                                                        " "
                                                    )[0]
                                                ),
                                                Number(
                                                    entity.data.initialPayout
                                                ),
                                            ],
                                            [
                                                Number(entity.data.dayDiff),
                                                Number(entity.data.finalPayout),
                                            ],
                                        ]
                                    ),
                                accountIds: entity?.data?.accountIds ?? [],
                                accountIdsPaths,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case initialBalanceObject.constant(): {
                            const data = {
                                id: entity.id,
                                cadence: "one-time",
                                startDate:
                                    entity?.startDate ||
                                    moment().format("YYYY-MM-DD"),
                                value: Number(entity.data.value ?? 0),
                                type: entity.type,
                                accountIdsPaths: [entity.data.accountIds],
                                accountIds: entity.data.accountIds,
                                accountName: entity.data.accountName,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case growthObject.constant(): {
                            const modifierNodeEntities = [];

                            let entitiesIds = entity.data?.selectedIds;
                            if (entitiesIds?.length === 0) {
                                const event = getEvent(
                                    entity.data?.selectedEventId ?? ""
                                );
                                event
                                    ? (entitiesIds = event.entities.map(
                                          (entity) => entity.id
                                      ))
                                    : [];
                            }

                            const entities = Object.values(
                                getRelevantEntities(entitiesIds)
                            );
                            for (const targetEntity of entities) {
                                // Determine which values to send to decision engine based on the growthType
                                const value = {};

                                const growthType = entity?.data?.growthType;
                                value["growthType"] = growthType;
                                if (growthType === "constant") {
                                    value["constantGrowthRate"] =
                                        Number(
                                            entity?.data?.constantGrowthRate
                                        ) / 100;
                                } else {
                                    value["maxGrowthRate"] =
                                        entity?.data?.maxGrowthRate;
                                    value["subAction"] = entity?.data
                                        ?.interpolate
                                        ? "interpolate"
                                        : "step";
                                    value["specificPercentages"] =
                                        entity.data.growthSegments.reduce(
                                            function (map, obj) {
                                                map[obj.startDate] =
                                                    obj.monthlyRate;
                                                return map;
                                            },
                                            {}
                                        );
                                }

                                const feName =
                                    modifierEntityMap[entity?.data?.entityType][
                                        entity?.data?.property.split(" - ")?.[0]
                                    ]?.feName ?? "";

                                let targetStartDate = "";
                                if (targetEntity?.startDate) {
                                    const targetMoment = moment(
                                        targetEntity?.startDate
                                    );
                                    targetStartDate = targetMoment
                                        .set("date", 1)
                                        .format("YYYY-MM-DD");
                                }

                                const data = {
                                    id: `${entity.id} - ${targetEntity.id}`,
                                    cadence: entity?.cadence ?? "monthly",
                                    startDate: entity.data.customEffectPeriod
                                        ? entity.startDate
                                        : targetStartDate,
                                    endDate: entity.data.customEffectPeriod
                                        ? entity.endDate
                                        : "",
                                    nodeType: entity.data.entityType,
                                    feName: feName,
                                    keyToModify: entity?.data?.deName ?? "",
                                    value: value,
                                    action: "growth",
                                    entityId: targetEntity.id,
                                    revert:
                                        entity.data.customEffectPeriod ?? false,
                                    cadenceType: "Recurring",
                                };
                                modifierNodeEntities.push({
                                    id: data.id,
                                    active: true,
                                });
                                exportEntities[data.id] = data;
                            }

                            if (modifierNodes[node.id]?.length) {
                                modifierNodes[node.id].push(
                                    ...modifierNodeEntities
                                );
                            } else {
                                modifierNodes[node.id] = modifierNodeEntities;
                            }

                            break;
                        }
                        case salespersonObject.constant(): {
                            const employeeType = entity.data.employeeType;

                            // salesTargets is an array of objects that contains most of the information used by decision engine
                            const data = {
                                id: entity.id,
                                cadence: "monthly",
                                startDate: entity.startDate,
                                endDate: entity.endDate ?? "",
                                numEmployees:
                                    employeeType == "placeholder"
                                        ? Number(entity.data.totalSalespeople)
                                        : 1,
                                salesTargets: entity.data.salesTargets.map(
                                    (saleTarget) => {
                                        const outboundSalesEntityData =
                                            Object.values(
                                                getRelevantEntities(
                                                    saleTarget?.outboundSales
                                                        ?.entityIds
                                                ) ?? {}
                                            )?.[0];
                                        const customerEntityIds =
                                            outboundSalesEntityData?.data
                                                ?.customerIds;
                                        const customerEntityData =
                                            Object.values(
                                                getRelevantEntities(
                                                    customerEntityIds
                                                ) ?? {}
                                            )?.[0];
                                        // could remove the outboundSales field from the saleTarget as it's not used by decision engine
                                        return {
                                            ...saleTarget,
                                            customerAccountId:
                                                customerEntityData?.data
                                                    ?.parentAccountId ?? "",
                                            grownCustomerAccountId:
                                                customerEntityData?.data
                                                    ?.grownAccountId ?? "",
                                            timePercentage:
                                                Number(
                                                    saleTarget.timePercentage
                                                ) * 0.01,
                                            multiplier: Number(
                                                saleTarget.multiplier
                                            ),
                                            next: entity.startDate,
                                        };
                                    }
                                ),
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case segmentObject.constant(): {
                            break;
                        }
                        case allocationObject.constant(): {
                            const customerEntity = getRelevantEntities(
                                entity?.data?.customerIds ?? []
                            )[entity?.data?.customerIds[0] ?? ""];
                            const customerStartDate =
                                customerEntity?.startDate ?? "";
                            const customerAccountId =
                                customerEntity?.data?.parentAccountId ?? "";

                            const segmentEntity = getRelevantEntities(
                                entity?.data?.segmentIds ?? []
                            )[entity?.data?.segmentIds[0] ?? ""];

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                cadence: "monthly",
                                startDate: customerStartDate,
                                customerAccountId: customerAccountId,
                                segments: entity?.data?.segments ?? [],
                                accountIdsPaths:
                                    segmentEntity?.data?.accountIdsPaths ?? [],
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case campaignObject.constant(): {
                            const cashPath = [
                                "211dd944-359f-4fbe-af1f-0c761afa1e67",
                                "76919215-1601-4634-811f-54d3af8b8fa9",
                                "61f75c52-c089-4137-b424-e2f7bfc4d340",
                            ];

                            const websiteVisitorEntity = Object.values(
                                getRelevantEntities(
                                    entity?.data?.websiteVisIds ?? []
                                )
                            )[0];

                            const parentAccountId =
                                websiteVisitorEntity?.data?.parentAccountId ??
                                "";
                            const paidAccountId =
                                websiteVisitorEntity?.data?.paidAccountId ?? "";

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                endDate: entity.endDate,
                                cadence: entity.cadence,
                                adSpend: Number(entity.data.value) ?? 0,
                                costPerVisit:
                                    Number(entity.data.costPerVisit) ?? 1,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    cashPath,
                                ],
                                accountIds: entity.data.accountIds,
                                parentAccountId: parentAccountId,
                                paidAccountId: paidAccountId,
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case websiteVisitorsObject.constant(): {
                            const topLevelCustomerAccountId =
                                "db178274-8d95-4a6a-ae5c-46ae2fc356dd";
                            const subOrganicAccountId =
                                "8a43fb3e-62f5-47d7-917f-3a042354cb11";
                            const subPaidAccountId =
                                "7eb29f4d-d0ec-402e-a743-19797ebbd22b";

                            const organicAccountPath = [
                                topLevelCustomerAccountId,
                                subOrganicAccountId,
                            ];
                            const paidAccountPath = [
                                topLevelCustomerAccountId,
                                subPaidAccountId,
                            ];

                            const data = {
                                id: entity.id,
                                type: entity.type,
                                startDate: entity.startDate,
                                cadence: "monthly",
                                value: Number(entity.data.value) ?? 0,
                                cumulativeValue: 0, // just setting it here which is easier than doing it in de3
                                accountId: entity?.data?.parentAccountId ?? "",
                                organicAccountId:
                                    entity?.data?.organicAccountId ?? "",
                                paidAccountId:
                                    entity?.data?.paidAccountId ?? "",
                                accountIdsPaths: [
                                    ...(entity?.data?.accountIdsPaths ?? []),
                                    organicAccountPath,
                                    paidAccountPath,
                                ],
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case constraintObject.constant(): {
                            const modifierNodeEntities = [];

                            let entitiesIds = entity.data?.selectedIds;
                            if (entitiesIds?.length === 0) {
                                const event = getEvent(
                                    entity.data?.selectedEventId ?? ""
                                );
                                event
                                    ? (entitiesIds = event.entities.map(
                                          (entity) => entity.id
                                      ))
                                    : [];
                            }

                            const entities = Object.values(
                                getRelevantEntities(entitiesIds)
                            );

                            const feName =
                                modifierEntityMap[entity?.data?.entityType][
                                    entity?.data?.property.split(" - ")?.[0]
                                ]?.feName ?? "";

                            for (const targetEntity of entities) {
                                let targetStartDate = "";
                                if (targetEntity?.startDate) {
                                    const targetMoment = moment(
                                        targetEntity?.startDate
                                    );
                                    targetStartDate = targetMoment
                                        .set("date", 1)
                                        .format("YYYY-MM-DD");
                                }

                                const data = {
                                    id: `${entity.id} - ${targetEntity.id}`,
                                    cadence: entity?.cadence,
                                    startDate: entity.data.customEffectPeriod
                                        ? entity.startDate
                                        : targetStartDate,
                                    endDate: entity.data.customEffectPeriod
                                        ? entity.endDate
                                        : "",
                                    nodeType: entity?.data?.entityType ?? "",
                                    feName: feName,
                                    keyToModify: entity?.data?.deName ?? "",
                                    value: {
                                        minValue:
                                            Number(entity?.data?.minValue) ||
                                            undefined,
                                        maxValue:
                                            Number(entity?.data?.maxValue) ||
                                            undefined,
                                    },
                                    action: "constrain",
                                    entityId: targetEntity.id,
                                    revert: entity.data.customEffectPeriod,
                                    cadenceType: "Recurring",
                                };

                                modifierNodeEntities.push({
                                    id: data.id,
                                    active: true,
                                });

                                exportEntities[data.id] = data;
                            }
                            modifierNodes[node.id] = modifierNodeEntities;
                            break;
                        }
                        case CAC2Object.constant(): {
                            const associatedEntityIds =
                                this.getEntityDependencies(
                                    entity.dependencies,
                                    "entity"
                                );

                            let entityThread = [];
                            // the every method breaks out of the loop if it encounters a falsy value
                            scenarioThreads.forEach((thread) => {
                                const { signature, nodes } = thread;
                                nodes.every((threadNode) => {
                                    if (threadNode.id === node.id) {
                                        entityThread.push(signature);
                                        // node exists in this thread so we exit the loop
                                        return false;
                                    }
                                    return true;
                                });
                            });

                            const scenarioLength = this.getScenarioLength();

                            for (const entityId of associatedEntityIds) {
                                let totalScenarioLength = scenarioLength;

                                let startDate = entity.startDate;

                                // these are for appending to the values and functions map in case the startDate for those are later than for the customer entity, in which case it messes up the overmods in de3 since when there are modifiers overrides are run at the same time and wouldn't be run if modifiers for a date doesn't exist
                                let extraValuesMap = {};
                                let extraFunctionsMap = {};

                                if (entity.data.inheritStartDate) {
                                    const modEntityStartDate =
                                        this.getCustomerDateDependencies(
                                            entityId
                                        );

                                    // growth starts one month after
                                    const newStartDate = moment(
                                        modEntityStartDate
                                    )
                                        .add(1, "M")
                                        .toISOString()
                                        .slice(0, 7)
                                        .concat("-01");

                                    startDate = newStartDate;

                                    // if the customer starts before the scenario start date, the scenario length becomes longer
                                    if (
                                        moment(startDate).isBefore(
                                            moment(scenarioStartDate)
                                        )
                                    ) {
                                        const monthsDifference = moment(
                                            scenarioStartDate
                                        ).diff(moment(startDate), "months");
                                        totalScenarioLength = Number(
                                            totalScenarioLength +
                                                monthsDifference / 12
                                        );
                                    }

                                    if (
                                        moment(modEntityStartDate).isBefore(
                                            moment(startDate),
                                            "months"
                                        )
                                    ) {
                                        extraValuesMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            totalScenarioLength,
                                            {
                                                isModifier: true,
                                                threadId: entityThread,
                                                value: 0,
                                            }
                                        );

                                        extraFunctionsMap = createCadenceArray(
                                            "monthly",
                                            modEntityStartDate,
                                            startDate,
                                            totalScenarioLength,
                                            "add"
                                        );
                                    }
                                }

                                const valuesMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    totalScenarioLength,
                                    {
                                        isModifier: true,
                                        threadId: entityThread,
                                    }
                                );

                                if (entity.data.costType == "constant") {
                                    const cost = Number(
                                        entity.data.constantCost
                                    );
                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            value: cost,
                                        };
                                    }
                                } else if (entity.data.costType == "dynamic") {
                                    const simplifiedSegmentMap =
                                        getSimplifiedSegmentMap(
                                            "monthly",
                                            startDate,
                                            entity.endDate,
                                            totalScenarioLength,
                                            entity.data.costSegments,
                                            entity.data.interpolate
                                        );

                                    for (const [date, data] of Object.entries(
                                        valuesMap
                                    )) {
                                        valuesMap[date] = {
                                            isModifier: data.isModifier,
                                            threadId: data.threadId,
                                            value: simplifiedSegmentMap[date],
                                        };
                                    }
                                }

                                const functionsMap = createCadenceArray(
                                    "monthly",
                                    startDate,
                                    entity.endDate,
                                    totalScenarioLength,
                                    "applyCAC"
                                );

                                for (const [date, val] of Object.entries(
                                    extraValuesMap
                                )) {
                                    if (!(date in valuesMap)) {
                                        valuesMap[date] = val;
                                    }
                                }

                                for (const [date, val] of Object.entries(
                                    extraFunctionsMap
                                )) {
                                    if (!(date in functionsMap)) {
                                        functionsMap[date] = val;
                                    }
                                }

                                const override = {
                                    field: "value",
                                    defaultValue: null,
                                    cadence: "monthly",
                                    values: valuesMap,
                                    functions: functionsMap,
                                };

                                if (exportModifiers[entityId]) {
                                    exportModifiers[entityId].push(override);
                                } else {
                                    exportModifiers[entityId] = [override];
                                }
                            }

                            break;
                        }
                        case percentageObject.constant(): {
                            const customPeriod = entity.data.customEffectPeriod;

                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                value: Number(entity?.data?.value) / 100 ?? 0,
                                startDate: customPeriod
                                    ? entity.startDate
                                    : scenarioStartDate,
                                endDate: customPeriod ? entity.endDate : "",
                                debitOrCredit:
                                    entity?.data?.debitOrCredit ?? "debit",
                                sourceAccountIds: entity.data.sourceAccountIds,
                                targetAccountIds: entity.data.targetAccountIds,
                                targetContraAccountIds:
                                    entity.data.targetContraAccountIds,
                                accountIdsPaths: [
                                    entity.data.sourceAccountIds,
                                    entity.data.targetAccountIds,
                                    entity.data.targetContraAccountIds,
                                ],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case resourceObject.constant(): {
                            let start = entity.startDate;
                            let end = entity.endDate;
                            let targetEntityIds = [];

                            if (
                                entity.data.inheritedStartDate ||
                                entity.data.inheritedEndDate
                            ) {
                                targetEntityIds =
                                    entity?.data?.selectedIds ?? [];
                                const targetEntity = Object.values(
                                    getRelevantEntities(
                                        entity?.data?.selectedIds ?? []
                                    )
                                )[0];

                                if (entity.data.inheritedStartDate)
                                    start = targetEntity.startDate;
                                if (entity.data.inheritedEndDate)
                                    end =
                                        targetEntity.data.modifiedEnd ||
                                        targetEntity.endDate;
                            }

                            let distribution = entity.data.distribution;
                            if (entity.data.setOrDistributed === "set") {
                                // Create a flat, normalized distribution with months points

                                const months =
                                    moment(end).diff(moment(start), "months") +
                                    1;
                                distribution = Array(months).fill(1 / months);
                            }

                            const exportedAccountIds = []
                                .concat(entity.data.accountIds)
                                .concat(entity.data.contraAccountIds)
                                .concat(entity.data.adjustedAccountIds);

                            entity.data.accountIds = exportedAccountIds;

                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                cadence: "monthly",
                                value: Number(entity?.data?.value) ?? 0,
                                startDate: start,
                                unmodifiedStartDate: start,
                                endDate: end,
                                unmodifiedEndDate: end,
                                setOrDistributed:
                                    entity?.data?.setOrDistributed ?? "set",
                                distribution: distribution,
                                targetEntity: targetEntityIds,
                                accountIds: exportedAccountIds,
                                neededAccountIds: entity.data.accountIds,
                                contraAccountIds: entity.data.contraAccountIds,
                                adjustedAccountIds:
                                    entity.data.adjustedAccountIds,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                    entity.data.adjustedAccountIds,
                                ],
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case utilizationObject.constant(): {
                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                cadence: "daily",
                                value: Number(entity?.data?.value) ?? 0,
                                resourceValue: entity?.data?.resourceValue,
                                utilizedStart: entity?.data?.lastUpdated,
                                startDate: entity.data.resourceStart,
                                endDate: entity.data.resourceEnd,
                                accountIdsPaths: [
                                    entity.data.accountIds,
                                    entity.data.contraAccountIds,
                                ],
                                accountIds: entity.data.accountIds,
                                contraAccountIds: entity.data.contraAccountIds,
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case capacityObject.constant(): {
                            const employeePath =
                                entity?.data?.employeePath ?? [];
                            const capacityPath =
                                entity?.data?.capacityPath ?? [];

                            const accountIds = []
                                .concat(employeePath)
                                .concat(capacityPath)
                                .concat(entity.data.contraAccountIds)
                                .concat(entity.data.utilizedAccountIds);

                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                startDate: entity?.startDate,
                                endDate: entity?.endDate,
                                cadence: entity.cadence,
                                value: entity?.data?.value ?? 100,
                                workDays: entity?.data?.workDays ?? 5,
                                employeePath,
                                capacityPath,
                                accountIds: entity.data.accountIds,
                                accountIdsPaths: entity.data.accountIdsPaths,
                                employeeAccountIds:
                                    entity.data.employeeAccountIds,
                                contraAccountIds: entity.data.contraAccountIds,
                                utilizedAccountIds:
                                    entity.data.utilizedAccountIds,
                            };

                            exportEntities[entity.id] = data;
                            break;
                        }
                        case averageOrderObject.constant(): {
                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                startDate: scenarioCalculationStart,
                                cadence: "daily",
                                scheduleCadence: entity?.cadence ?? "monthly",
                                schedule: entity?.data?.schedule ?? [],
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case accountExpressionObject.constant(): {
                            const { expressionStepsIds, expressionStepsMap } =
                                entity?.data;

                            const targetAccountIds =
                                entity?.data?.targetAccountIds ?? [];
                            const targetContraAccountIds =
                                entity?.data?.targetContraAccountIds ?? [];

                            const expressionSteps = [];
                            const accountIds = [
                                ...targetAccountIds,
                                ...targetContraAccountIds,
                            ];
                            const accountIdsPaths = [
                                [...targetAccountIds],
                                [...targetContraAccountIds],
                            ];

                            expressionStepsIds?.forEach((expressionId) => {
                                const expression =
                                    expressionStepsMap?.[expressionId];
                                const { leftValue, rightValue } = expression;
                                if (leftValue !== "result") {
                                    accountIds.push(leftValue);
                                    accountIdsPaths.push(
                                        recurseToTopAccount(leftValue)
                                    );
                                }
                                if (rightValue !== "result") {
                                    accountIds.push(rightValue);
                                    accountIdsPaths.push(
                                        recurseToTopAccount(rightValue)
                                    );
                                }
                                expressionSteps.push(expression);
                            });

                            entity.data.accountIds = accountIds;
                            entity.data.accountIdsPaths = accountIdsPaths;
                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                startDate: entity?.startDate,
                                endDate: entity?.endDate,
                                targetAccountIds:
                                    entity?.data?.targetAccountIds,
                                targetContraAccountIds:
                                    entity?.data?.targetContraAccountIds ?? [],
                                expressionSteps: expressionSteps,
                                accountIds: accountIds,
                                accountIdsPaths: accountIdsPaths,
                            };
                            exportEntities[entity.id] = data;
                            break;
                        }
                        case nullObject.constant(): {
                            break;
                        }
                        case containerObject.constant(): {
                            break;
                        }
                        case startObject.constant(): {
                            break;
                        }
                        case unitObject.constant(): {
                            const isOngoing =
                                entity.data.amortizedOrOngoing === "ongoing";
                            let data;
                            if (isOngoing) {
                                data = {
                                    id: entity.id,
                                    type: entity.type,
                                    cadence: entity.cadence,
                                    startDate: entity.startDate,
                                    endDate: entity.endDate,
                                    value: Number(entity.data.value),
                                    inflation: entity.data.inflation,
                                    inflationRate: Number(
                                        loadedScenario.inflation
                                    ),
                                    rating: entity.data.rating,
                                    accountIdsPaths: [
                                        entity.data.accountIds,
                                        entity.data.contraAccountIds,
                                    ],
                                    accountIds: entity.data.accountIds,
                                    contraAccountIds:
                                        entity.data.contraAccountIds,
                                    accountName: entity.data.accountName,
                                    increaseOrDecrease:
                                        entity.data.increaseOrDecrease,
                                };
                            } else {
                                // Whenever you give moment a date, it puts that date
                                // at 00:00, so something like 2024-01-01 and 2024-01-02
                                // are exactly 24 hours apart. However, DE includes that
                                // end date, and also puts the end time at 23:59, so
                                // 2024-01-01 and 2024-01-02 are a day and 23:59 hours
                                // apart.
                                const days =
                                    moment(entity.data.amortizedEnd).diff(
                                        moment(entity.startDate),
                                        "days"
                                    ) + 1;

                                const calculatedAmortizedValue =
                                    parseFloat(entity.data.amortizedValue) /
                                    days;

                                data = {
                                    id: entity.id,
                                    type: entity.type,
                                    cadence: "daily",
                                    startDate: entity.startDate,
                                    endDate: entity.data.amortizedEnd,
                                    value: Number(calculatedAmortizedValue),
                                    inflation: entity.data.inflation,
                                    inflationRate: Number(
                                        loadedScenario.inflation
                                    ),
                                    rating: entity.data.rating,
                                    accountIdsPaths: [
                                        entity.data.accountIds,
                                        entity.data.contraAccountIds,
                                    ],
                                    accountIds: entity.data.accountIds,
                                    contraAccountIds:
                                        entity.data.contraAccountIds,
                                    accountName: entity.data.accountName,
                                    increaseOrDecrease:
                                        entity.data.increaseOrDecrease,
                                };
                            }

                            exportEntities[entity.id] = data;
                            break;
                        }
                        default: {
                            const data = {
                                id: entity.id,
                                nodeType: entity.type,
                                entities: entity.entities,
                            };
                            exportEntities[entity.id] = data;
                        }
                    }

                    // Account modifiers utilise overrides differently, they do not need to create implicit modifiers.
                    if (
                        entity?.data?.modsCreated?.length &&
                        entity.type !== accountModifierObject.constant()
                    ) {
                        for (const mod of entity?.data?.modsCreated) {
                            if (implicitModifiers[node.id]) {
                                implicitModifiers[node.id].push(mod);
                            } else {
                                implicitModifiers[node.id] = [mod];
                            }
                        }
                    }

                    ledgerMap = addRelevantLedger(entity, ledgerMap);
                }
            }
            toDelete.forEach((id) => {
                delete exportEntities[id];
            });
            let exportThreads = scenarioThreads.map((thread) => {
                const { signature, name, nodes } = thread;

                const filteredNodes = nodes.filter((node) => {
                    //this code is for Excluding baseline value that is not included in Me node
                    return (
                        node.isFilled &&
                        node.valid &&
                        !node.bypassed &&
                        !node.isMovedToBaseline() &&
                        !(
                            startEntity?.data.excludedBaselineNode.map(
                                (id) => "baseline-" + id
                            ) ?? []
                        ).includes(node.id) &&
                        node.type !== containerObject.constant() && // Container nodes cannot go to DE, or DE breaks
                        node.type !== "Baseline" &&
                        node.type !== startObject.constant()
                    );
                });

                // ** This chunk of code here adds the IDs of Modifiers that are "built-in" Events
                // (eg. Mortgage) to the list of IDs for each thread (for export to DE)
                const filteredNodesWithModifiers = filteredNodes.map((node) => {
                    const nodeWithModifiers = [];
                    if (
                        // ** We only check the first entity for modifiers, if we ever add an event
                        // ** which has optional modifiers we need to check all entities
                        (implicitModifiers[node.id]?.length ?? 0) > 0
                    ) {
                        const createdModifierEvent = {
                            id: uuid.v4(),
                            nodeType: modifierObject.constant(),
                            entities: [],
                            aggregate: true,
                        };
                        for (const mod of implicitModifiers[node.id]) {
                            exportEntities[mod.id] = mod;
                            createdModifierEvent.entities.push(mod.id);
                        }
                        nodeWithModifiers.push(createdModifierEvent);
                        nodeWithModifiers.push({
                            id: node.id,
                            nodeType: node.type,
                            entities: node.entities.map((entity) => entity.id),
                            aggregate: true,
                        });
                        return nodeWithModifiers;
                    } else if (modifierNodes[node.id]) {
                        return {
                            id: node.id,
                            nodeType: modifierObject.constant(),
                            entities: modifierNodes[node.id].map(
                                (entity) => entity.id
                            ),
                            aggregate: true,
                        };
                    } else {
                        // This is the structure of events in DE3
                        return {
                            id: node.id,
                            nodeType: node.type,
                            entities: node.entities.map((entity) => entity.id),
                            aggregate: true,
                        };
                    }
                });

                for (let i = 0; i < filteredNodesWithModifiers.length; i++) {
                    if (Array.isArray(filteredNodesWithModifiers[i])) {
                        const localCopyOfArray = [
                            ...filteredNodesWithModifiers[i],
                        ];
                        filteredNodesWithModifiers.splice(i, 1);
                        for (let j = 0; j < localCopyOfArray.length; j++) {
                            filteredNodesWithModifiers.splice(
                                i,
                                0,
                                localCopyOfArray[j]
                            );
                        }
                    }
                }

                return {
                    signature,
                    name,
                    nodes: filteredNodesWithModifiers,
                };
            });

            let delay = 0;

            // const exportLedgers = getRelevantLedgers(nodesWithIncludedBaseline);

            // Getting the relevant non-cumulative ledgers, removing "Cashflow" & "Net Worth" and sorting array.
            // We need ONLY non-cumulative ledger names to display as selectable options, and we will look up their
            // Cumulative versions using the Monthly/Cumulative toggles in the UI.
            const cashflowUUID = "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78";
            const calcEquityUUID = "a4d432a8-26ab-41f0-9008-88ae2d171378";
            const profitUUID = "4db53c56-db20-4798-9830-adbfcc977b26";

            const nonCumulativeLedgerNames = Object.values(ledgerMap)
                .map((ledger) => {
                    return { name: ledger.Name, id: ledger.Id };
                })
                .filter((ledger) => !ledger.name.includes("Cumulative"))
                .filter(
                    (ledger) =>
                        !(
                            ledger.id === cashflowUUID ||
                            ledger.id === calcEquityUUID ||
                            ledger.id === profitUUID
                        )
                )
                .sort((a, b) => {
                    if (a.name[0] > b.name[0]) return 1;
                    if (a.name[0] < b.name[0]) return -1;
                    return 0;
                });

            // ... & putting "Cashflow" & "Net Worth" in front so they show first
            const sortedLedgersArray = [
                {
                    name: allAccountLedgers[cashflowUUID].name,
                    id: allAccountLedgers[cashflowUUID].id,
                },
                {
                    name: allAccountLedgers[calcEquityUUID].name,
                    id: allAccountLedgers[calcEquityUUID].id,
                },
                {
                    name: allAccountLedgers[profitUUID].name,
                    id: allAccountLedgers[profitUUID].id,
                },
            ].concat(nonCumulativeLedgerNames);

            const sendCalculation = () => {
                    this.props.dispatch(
                        upsertAllAccountLedgers({
                            relevantLedgers: sortedLedgersArray,
                        })
                    );

                    this.props.calculateScenario(
                        exportThreads,
                        exportEntities,
                        ledgerMap,
                        moment().format("YYYY-MM-DD hh:mm:ss.SSS"),
                        modifiedRange,
                        loadedScenario.id,
                        scenarioCalculationStart,
                        scenarioStartDate,
                        exportOverrides,
                        exportInstances,
                        exportModifiers
                    );

                    for (let i = 0; i < exportThreads.length; i++) {
                        const mortgageContext = generateContextForMortgage(
                            exportThreads[i],
                            exportEntities
                        );
                        this.props.dispatch(
                            upsertCalculatedThreads({
                                [exportThreads[i].signature]: {
                                    context: mortgageContext,
                                },
                            })
                        );
                    }
                },
                calcRequest = moment(),
                thresholdTimer = 1;

            if (this.state.lastCalculate) {
                const threshold = moment(this.state.lastCalculate).add(
                    "seconds",
                    thresholdTimer
                );
                if (threshold.diff(calcRequest) > 0) {
                    window.clearTimeout(this.state.calculateTimer);
                    delay = thresholdTimer * 5; // 5 milliseconds
                }
            }

            this.setState({
                calculateTimer: setTimeout(sendCalculation, delay),
                lastCalculate: calcRequest,
            });
        }
    };

    getRetirementDependencies = (node, nodesWithBaseline) => {
        const retirementDate = moment(node.metadata.start);
        const incomeIds = [],
            pensionIds = [],
            rrspIds = [],
            investmentIds = [];
        nodesWithBaseline.forEach((curNode) => {
            if (curNode.metadata.personId === node.metadata.personId) {
                if (curNode.type === incomeObject.constant()) {
                    if (
                        curNode.metadata.disableOnRetirement &&
                        moment(curNode.metadata.start).isBefore(
                            retirementDate
                        ) &&
                        (!curNode.metadata.end ||
                            (curNode.metadata.end &&
                                moment(curNode.metadata.end).isAfter(
                                    retirementDate
                                )))
                    ) {
                        incomeIds.push(curNode.id);
                    }
                } else if (curNode.type === Pension) {
                    pensionIds.push(curNode.id);
                } else if (curNode.type === rrspObject.constant()) {
                    if (curNode.metadata.disableOnRetirement) {
                        rrspIds.push(curNode.id);
                    }
                } else if (curNode.type === investmentObject.constant()) {
                    if (curNode.metadata.disableOnRetirement) {
                        investmentIds.push(curNode.id);
                    }
                }
            }
        });

        return { incomeIds, pensionIds, rrspIds, investmentIds };
    };

    getGraduationDependencies = (node, nodesWithBaseline) => {
        const incomeIds = [],
            studentLoanIds = [];
        nodesWithBaseline.forEach((curNode) => {
            if (curNode.type === "Hourly Income") {
                incomeIds.push(curNode.id);
            } else if (curNode.type === "Student Loan") {
                studentLoanIds.push(curNode.id);
            }
        });

        return { incomeIds, studentLoanIds };
    };

    getCustomerDateDependencies = (customerId) => {
        return getRelevantEntities([customerId])[customerId]?.startDate;
    };

    /**
     *
     * @param {{[key: string]: {entityIds: string[], entityId: string}}} dependencies
     * @param {string} dependencyName
     * @returns
     */
    getEntityDependencies = (dependencies, dependencyName) => {
        const dependencyObject = dependencies?.[dependencyName];
        if (dependencyObject === undefined) return [];

        const getEntities = (entityIds) => {
            const entities = getRelevantEntities(entityIds);
            let newEntities = [];
            for (const [id, entity] of Object.entries(entities)) {
                // if it's a group entity, include the selected entities/events instead
                if (entity.type === groupObject.constant()) {
                    const groupMode = entity.data.groupMode;
                    if (groupMode == "events") {
                        const groupSelectedEvents = entity.data.selectedEvents;
                        const includedEntities = [];
                        groupSelectedEvents.forEach((selectedEvent) => {
                            const event = getEvent(selectedEvent);
                            const entities = getRelevantEntities(
                                event.entities
                            );
                            Object.values(entities).forEach((entity) => {
                                includedEntities.push(entity.id);
                            });
                        });

                        newEntities = newEntities.concat(includedEntities);
                    } else if (groupMode == "entities") {
                        newEntities = newEntities.concat(
                            entity.data.selectedEntities
                        );
                    }
                } else {
                    newEntities.push(id);
                }
            }

            return newEntities;
        };

        if (dependencyObject.entityIds.length === 0) {
            return Object.keys(
                getRelevantEntities(
                    getEvent(dependencyObject.eventId)?.entities ?? []
                )
            );
        } else {
            return getEntities(dependencyObject.entityIds);
        }
    };

    updateFocus = (focus) => {
        this.props.setFocus(this.manager._findEvent(focus));
    };

    updateScenarioCanvas = (nodeData) => {
        const { loadedScenario } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const manager = this.manager;
        if (loggedInUser) {
            const isPassed = loadedScenario && loadedScenario.type === "shared";
            const normalScenario =
                loadedScenario && loadedScenario.type === "scenario";
            if (loadedScenario && (normalScenario || isPassed)) {
                const data = nodeData;
                const scenario = {
                    ...loadedScenario,
                    data,
                };

                const newScenario = {
                    ...loadedScenario,
                    data: nodeData,
                };

                this.props.updateScenarioCanvas(
                    scenario,
                    newScenario,
                    manager,
                    isPassed,
                    this.props.baselineDataManager,
                    (_data) => {
                        if (isPassed && loggedInUser) {
                            this.props.fetchSharedScenarios(
                                loggedInUser.account
                            );
                        } else {
                            this.props.getUserScenarios();
                        }
                    }
                );
            } else {
                // We are logged in but the scenario is neither ours or shared with us
                // As of right now this only occurs for sample scenarios
                const newScenario = {
                    ...loadedScenario,
                    data: nodeData,
                };
                this.props.loadScenario(
                    newScenario,
                    manager,
                    this.props.baselineDataManager
                );
            }
        } else {
            // We are not logged in
            const newScenario = {
                ...loadedScenario,
                data: nodeData,
            };
            this.props.loadScenario(
                newScenario,
                manager,
                this.props.baselineDataManager
            );
        }
    };

    updateCanvas = (sendToDecisionEngine = true) => {
        // const manager = sendToDecisionEngine
        //     ? this.modelManager
        //     : this.displayManager;
        // const highlightedThread = this.props.highlightedThread || {};
        const manager = this.manager;
        const { highlightedThread } = this.state;

        let nodes = manager.allNodes();
        const sortedEventIds = manager.sortedEventIds;
        const sortedNodes = [];
        const sortedCustomerChurn2Nodes = [];
        if (sortedEventIds.length > 0) {
            for (const id of sortedEventIds) {
                for (let i = 0; i < nodes.length; i++) {
                    const node = nodes[i];
                    if (node.id == id) {
                        if (node.type == customerChurn2Object.constant()) {
                            sortedCustomerChurn2Nodes.push(node);
                        } else {
                            sortedNodes.push(node);
                        }

                        nodes.splice(i, 1);
                    }
                }
            }

            // customer churn 2 nodes need to always come after customer 2, customer growth 2, and customer transfer 2
            nodes = sortedNodes.concat(sortedCustomerChurn2Nodes);
        }
        const scenarioThreads = manager.scenarioThreads || [];
        const details = {
            nodes,
            scenarioThreads,
        };
        if (sendToDecisionEngine) {
            details.scenarioThreads.map((thread) => {
                thread.nodes = manager.baseline
                    ? manager.baseline.concat(thread.nodes)
                    : thread.nodes;
                thread.nodes = _.uniqBy(thread.nodes, "id");
                return thread;
            });
        }

        const { signature } = highlightedThread;
        if (signature) {
            let exists = false;
            details.scenarioThreads.forEach((thread) => {
                if (thread.signature === signature) {
                    exists = true;
                }
            });

            if (!exists) {
                details["highlightedThread"] = {};
            }
        }
        this.setState(details);
        if (sendToDecisionEngine) {
            if (
                !_.isEmpty(store.getState().allAccountLedgers.ledgersMetadata)
            ) {
                this.exportForCalculations(details);
            }
        }
    };

    addEvent = (
        type = "c948e1e2-acac-4f8c-b241-92a16d4528b7",
        parents = [],
        children = []
    ) => {
        this.setState({ optionType: type, parents, children });
    };

    //todo: code duplication w/ Baseline.js - refactor toast functionality to a reusable component
    deleteEvent = (node) => {
        const { loadedScenario } = this.props;
        if (loadedScenario.type === "sample") {
            if (isActiveUserAdmin()) {
                const deleteNode = this.manager.deleteEvent(node);
                this.updateEvents();
                const toastData = {
                    type: "warnings",
                    text: `Delete "${node.name}"?`,
                    upgrade: false,
                };
                this.setState({
                    deleteNode,
                    toastData,
                });
            } else {
                const toastData = {
                    type: "error",
                    text: "Please copy the Sample Scenario if you want to modify it.",
                    upgrade: false,
                };
                this.setState(
                    {
                        toastData,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ toastData: null });
                        }, 7000);
                    }
                );
            }
        } else {
            // const deleteNode = this.modelManager.deleteEvent(node);
            const deleteNode = this.manager.deleteEvent(node);
            this.updateEvents();
            const toastData = {
                type: "warnings",
                text: `Delete "${node.name}"?`,
                upgrade: false,
            };
            this.setState({
                deleteNode,
                toastData,
            });
        }
    };

    deletePrompt = () => {
        const { root, newRoot, toDelete } = this.state.deleteNode;
        // this.modelManager.deleteNodes(root.id, newRoot, toDelete);
        this.manager.deleteNodes(root.id, newRoot, toDelete);
        this.updateEvents();
        const toastData = {
            type: "success",
            text: `"${root.name}" Event was Deleted`,
            upgrade: false,
        };

        this.setState(
            {
                toastData,
                deleteNode: null,
            },
            () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            }
        );
    };

    cancelDeletePrompt = () => {
        this.setState({ deleteNode: null, toastData: null });
        // this.modelManager.cancelDelete();
        this.manager.cancelDelete();
    };

    updateEvents = () => {
        this.manager.calculate();
    };

    loadMapDetails = () => {
        const { baselineDataManager, loadedScenario } = this.props;

        const { excludedBaselineNodes } = this.state;

        setTimeout(() => {
            const baselineScenarioEvents = loadedScenario
                ? [...loadedScenario.data.nodes]
                : [];
            const baseline =
                baselineDataManager &&
                baselineDataManager.hasActiveBaseline() &&
                baselineDataManager.getActiveBaseline();
            const numBaselineNodes = baseline ? baseline.data.nodes.length : 0;
            for (let i = 0; i < numBaselineNodes; i++) {
                baselineScenarioEvents.push(
                    baselineDataManager.activeBaseline.data.nodes[i]
                );
            }

            let processedExcludedBaselineEvents = [...excludedBaselineNodes];

            // removes the "baseline-" word in front of the baseline node ID's
            for (let i = 0; i < processedExcludedBaselineEvents.length; i++) {
                processedExcludedBaselineEvents[i] =
                    processedExcludedBaselineEvents[i].slice(9);
            }

            const processedBaselineScenarioEvents =
                baselineScenarioEvents.filter(
                    (event) =>
                        !processedExcludedBaselineEvents.includes(event.id)
                );

            const firstHouse = baselineScenarioEvents.find(
                (event) => event.type === "House"
            );

            this.setState({
                allEvents: processedBaselineScenarioEvents,
                firstHouseEvent: firstHouse,
            });
        }, 1500);
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.props.setManager(this.manager);

        //resize window
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser?.id) {
            window.userGuiding.identify(loggedInUser?.id, {
                email: loggedInUser?.email,
                name: loggedInUser?.name,
                timeStamp: new Date()?.getTime(),
            });
        } else {
            window.userGuiding.identify(uuid.v4());
        }
        const id = this.props.match.params.id;

        if (this.props.match.path === "/shareScenario/:id" && id) {
            this.retrieveAndLoadLinkedScenario(id);
        } else if (loggedInUser) {
            this.props.getAdminValues("PTTValues");
            this.props.getUserScenarios();
            this.props.fetchSharedScenarios(loggedInUser.account);
            this.setState({ landingPage: false });
            this.props.dispatch(getCards());
        } else {
            if (!loggedInUser) {
                const showSplashScreen = window.innerWidth < 800;
                if (!showSplashScreen) {
                    this.setState({
                        landingPage: true,
                    });
                }
            }
        }
        if (!this.props.onboardingData) {
            this.getLoadedScenario();
        }

        const {
            onboardingScenario,
            loadScenario,
            createScenario,
            getUserScenarios,
        } = this.props;

        if (this.props.onboardingData === 1) {
            LaunchOnboarding(
                // this.modelManager,
                this.manager,
                onboardingScenario,
                loadScenario,
                loggedInUser,
                createScenario,
                getUserScenarios
            );
        }

        if (
            this.props.selectedSampleScenario !== null &&
            this.props.history.location.pathname === "/"
        ) {
            loadScenario(this.props.selectedSampleScenario, this.manager);
            this.props.handleClickSampleScenarioCard(null);
        }

        this.setState({
            height: window.innerHeight,
            width: window.innerWidth,
            // dimensions: {
            //     width: this.container?.offsetWidth ?? 0,
            //     height: this.container?.offsetHeight ?? 0,
            // },
        });

        if (this.props.highlightedThread && this.props.baselineDataManager) {
            this.loadMapDetails();
        }

        // if (!loggedInUser && this.props.match.path === "/shareScenario/:id") {
        //     this.props.showLogin(true, GUEST);
        // }
    };

    // used for loading links e.g. app.whatifi.io/shareScenario/12345 where 12345 is scenario id
    // now gets scenario data returned from the getShareScenario promise
    retrieveAndLoadLinkedScenario = (id) => {
        Mixpanel.track("Shared Scenario", {
            scenarioId: id,
        });
        this.props.getSharedScenario(id).then((sharedScenario) => {
            if (sharedScenario && sharedScenario.length > 0) {
                const linkedScenario = sharedScenario[0];
                loadScenario(linkedScenario, this.manager);
            }
        });
    };

    getEventsForModifierNodes = () => {
        const { baselineDataManager, loadedScenario } = this.props;

        const { excludedBaselineNodes } = this.state;

        const baselineScenarioEvents = loadedScenario
            ? [...loadedScenario.data.nodes]
            : [];
        const baseline =
            baselineDataManager &&
            baselineDataManager.hasActiveBaseline() &&
            baselineDataManager.getActiveBaseline();
        const numBaselineNodes = baseline ? baseline.data.nodes.length : 0;
        for (let i = 0; i < numBaselineNodes; i++) {
            baselineScenarioEvents.push(
                baselineDataManager.activeBaseline.data.nodes[i]
            );
        }

        let processedExcludedBaselineEvents = [...excludedBaselineNodes];

        const processedBaselineScenarioEvents = baselineScenarioEvents.filter(
            (event) => !processedExcludedBaselineEvents.includes(event.id)
        );

        this.setState({
            scenarioAndBaselineEvents: processedBaselineScenarioEvents,
        });
    };

    updateBankInformation() {
        // TODO Make work for baseline
        // FOR SCENARIO
        // bank nodes will remain the same regardless of context so search flattened graph
        const { baselineDataManager, manager } = this.props;
        let nodes = this.state.nodes;
        if (baselineDataManager && baselineDataManager.hasActiveBaseline()) {
            nodes = nodes.concat(manager.baseline);
        }

        const flinksNodes = nodes.filter(
            (node) =>
                node.type === bankObject.constant() &&
                node.metadata.bankSelected === "flinks"
        );

        flinksNodes.forEach((node) => {
            const lastUpdated = node.metadata.lastUpdated;
            // for testing:
            // const lastUpdated = "2020-01-01";
            const today = moment(moment().format("YYYY-MM-DD"));
            const isInBaseline = node.isBaseline && node.isBaseline();

            if (moment(lastUpdated).isBefore(today)) {
                const id = localStorage.getItem(node.metadata.identifier);
                this.props
                    .updateFlinksData({
                        loginId: id,
                        update: true,
                    })
                    .then((value) => {
                        const accounts = value.data.Accounts;
                        if (accounts) {
                            node.metadata.bankData.Accounts = accounts;
                            //update selected accounts
                            let count = 0;
                            const selectedAccounts = node.metadata.selected;
                            selectedAccounts.forEach((selectedAcc) => {
                                accounts.forEach((updatedAcc) => {
                                    if (
                                        selectedAcc.AccountNumber ===
                                        updatedAcc.AccountNumber
                                    ) {
                                        node.metadata.selected[count] =
                                            updatedAcc;
                                    }
                                });
                                count++;
                            });
                            node.metadata.lastUpdated =
                                today.format("YYYY-MM-DD");
                            if (isInBaseline) {
                                const bl =
                                    baselineDataManager.getActiveBaseline(true);
                                const nodeInBaseline = bl.data.nodes.find(
                                    (entry) =>
                                        "baseline-" + entry.id === node.id
                                );
                                nodeInBaseline.metadata = { ...node.metadata };
                                this.props.updateBaseline(
                                    bl,
                                    baselineDataManager
                                );
                            } else {
                                this.props.updateNode(
                                    node.metadata,
                                    this.props.loadedScenario,
                                    this.props.manager,
                                    node,
                                    this.props.baselineDataManager
                                );
                            }
                            this.manager.calculate();
                            throwError(
                                "info",
                                "Bank Account Data Updated",
                                "We've updated your Bank events with the most recent data"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        this.props.setNodeGraphState("default");
    }

    getLoadedScenario = () => {
        function loadPreviousScenario(self, sampleScenarios) {
            const nonTutorialSampleScenarios = sampleScenarios?.filter(
                (sampleScenario) => !sampleScenario?.is_tutorial
            );
            const _prev = localStorage.getItem("loadedScenario"),
                previouslyLoadedScenario = _prev ? JSON.parse(_prev) : null;
            let toLoad =
                nonTutorialSampleScenarios?.length > 0
                    ? nonTutorialSampleScenarios[0]
                    : sampleScenarios[0];
            if (previouslyLoadedScenario) {
                Object.values(self.props.userScenarios).forEach(
                    (userScenario) => {
                        if (userScenario.id === previouslyLoadedScenario.id) {
                            toLoad = userScenario;
                        }
                    }
                );
                self.props.sharedScenario.forEach((sharedScenario) => {
                    if (sharedScenario.id === previouslyLoadedScenario.id) {
                        toLoad = sharedScenario;
                    }
                });

                sampleScenarios.forEach((sampleScenario) => {
                    if (sampleScenario.id === previouslyLoadedScenario.id) {
                        toLoad = sampleScenario;
                    }
                });
            }
            loadScenario(toLoad, self.manager);
            self.manager.updateCanvas();
        }
        if (
            this.props.sampleScenarios &&
            this.props.sampleScenarios.length > 0
        ) {
            loadPreviousScenario(this, this.props.sampleScenarios);
        } else {
            this.props.getTemplates().then((_res) => {
                loadPreviousScenario(this, this.props.sampleScenarios);
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.loadedUserScenarios &&
            !prevProps.loadedUserScenarios &&
            !this.props.onboardingData
        ) {
            this.getLoadedScenario();
        }

        if (prevProps.loadedScenario !== this.props.loadedScenario) {
            // 2020-04-04 [Added calc delay] Removed causing double updates
            // TODO: find right spot for this
            // this.updateBankInformation();

            // 2021-08-27
            // The following piece of code is a sketchy temporary solution to
            // omit sending data to DE3 if ONLY the ratings change for some
            // events. This is used to prevent recalculation when clicking the
            // "stars" in the canvas view of the application. What we eventually
            // want to do is to change loadedScenario into a scenario ID that
            // points to the actual scenario (as opposed to making an entire
            // copy of it).
            // Then, we perform saves/recalculates based off a direct action
            // rather than having it happen because of a side-effect (a listener).
            let diff = {};
            let omitRecalculation = false;
            try {
                diff = deepDiff(
                    this?.props?.loadedScenario ?? {},
                    prevProps?.loadedScenario ?? {}
                );
                omitRecalculation =
                    Object.keys(diff).length === 0 || // No changes
                    (diff.data?.nodes &&
                        Object.keys(diff).length === 1 &&
                        Object.keys(diff.data).length === 1 &&
                        diff.data.nodes.reduce((acc, curr) => {
                            // We only recalculate if a relevant field has been changed. Here we treat the checks as a
                            // whitelist because we currently have many fields on the event object, and may yet add more. So it's
                            // more efficient to check for what is worth updating for rather than what is not worth updating for.
                            return (
                                acc &&
                                curr?.bypassed === undefined &&
                                curr?.locked === undefined &&
                                curr?.entities === undefined &&
                                curr?.children === undefined &&
                                curr?.parents === undefined &&
                                curr?.isFilled === undefined &&
                                curr?.isValid === undefined
                            );
                        }, true));
            } catch (e) {
                console.error("mc ls - ERROR", e);
            }

            if (!store.getState().scenario.pauseDecisionEngine) {
                this.updateCanvas(!omitRecalculation);
            }
            // End of sketchy solution

            //    this.manager.calculate(null, null, false);
            this.setState({});
        }
        // if (
        //     prevState.dimensions &&
        //     (prevState.dimensions.width !== this.container?.offsetWidth ||
        //         prevState.dimensions.height !== this.container?.offsetHeight)
        // ) {
        //     this.setState({
        //         dimensions: {
        //             width: this.container?.offsetWidth ?? 0,
        //             height: this.container?.offsetHeight ?? 0,
        //         },
        //     });
        // }
        if (
            prevProps.onboardingData !== this.props.onboardingData &&
            this.props.onboardingData === 1
        ) {
            this.setState({});
        }

        let gotDecisionEngineRes = false; // TODO: This is used for context passing. Move to where we RECEIVE DE's response (or have each node handle context as discussed between Daniel & Benard)
        if (prevProps.calculatedThreads !== this.props.calculatedThreads) {
            // detect decision engine response
            gotDecisionEngineRes = true;
        }
        this.handleContextPassing(gotDecisionEngineRes);
        if (prevState.reload !== this.state.reload) {
            this.setState({ reload: false });
        }

        if (
            prevProps.loadedScenario !== this.props.loadedScenario ||
            prevProps.baselineDataManager !== this.props.baselineDataManager
        ) {
            this.loadMapDetails();

            const excludedBaselineNodes =
                Object.values(
                    getRelevantEntities(
                        this.manager
                            .getRootNode()
                            .entities.map((entity) => entity.id)
                    )
                )[0]?.data.excludedBaselineNode ?? [];

            this.setState({ excludedBaselineNodes: excludedBaselineNodes });
        }

        if (
            prevState.excludedBaselineNodes !== this.state.excludedBaselineNodes
        ) {
            this.loadMapDetails();
        }

        if (prevState.allEvents !== this.state.allEvents) {
            const allHouseEvents = this.state.allEvents.filter(
                (event) => event.type === "House"
            );
            this.setState({ allHouseEvents: allHouseEvents });
        }

        // if (prevProps.selectedThreads !== this.props.selectedThreads) {
        //     // NOTE to Daniel: Move these to mapState in the direct children who are using them. You can use a helper function for this logic if required.
        //     const scenarioThreadsArray = [...this.state.scenarioThreads];
        //     const selectedThreadsArray = [...this.props.selectedThreads];

        //     const selectedThreadsWithEvents = scenarioThreadsArray.filter(
        //         (thread) => {
        //             return selectedThreadsArray.find((selectedThread) => {
        //                 return thread.signature === selectedThread.id;
        //             });
        //         }
        //     );

        //     this.setState({
        //         selectedThreadsWithEvents: selectedThreadsWithEvents,
        //     });
        // }
    }

    handleContextPassing = (gotDecisionEngineRes) => {
        if (!this.props.highlightedThread) return;
        let threadId = this.props.highlightedThread.signature;
        if (!this.props.calculatedThreads[threadId]) return;
        let context = this.props.calculatedThreads[threadId].context;

        if (!context) return;

        let nodes = this.props.highlightedThread.nodes;
        let node;

        for (let [_key, value] of Object.entries(nodes)) {
            node = this.props.manager._findEvent(value.id);
            if (!node) continue;
            // if (!this.isContextSafeToProcess(node, context)) continue;
            const id = node.id;
            const type = node.type;

            switch (type) {
                // case Downpayment: {
                //     if (!contextData) break;

                //     const rateString = `${Number(
                //         node.metadata.downpaymentRate
                //     ).toFixed(2)} %`;
                //     const amountString = processNodeValueForCanvasDisplay(
                //         -1 * node.metadata.downpayment
                //     );

                //     if (
                //         node.metadata.lastChangedDownpaymentProperty ===
                //             DownpaymentRate &&
                //         node.nodeDetail !== rateString
                //     ) {
                //         node.nodeDetail = rateString;
                //         this.reloadManagerAndCanvas();
                //     } else if (
                //         node.metadata.lastChangedDownpaymentProperty ===
                //             DownpaymentAmount &&
                //         node.nodeDetail !== amountString
                //     ) {
                //         node.nodeDetail = amountString;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                case mortgageObject.constant(): {
                    const contextData = context?.[type]?.[id]?.data;

                    const threadContext = contextData?.[threadId];

                    if (
                        !threadContext ||
                        threadContext.initialPayment === undefined ||
                        threadContext.initialPayment === null ||
                        node.metadata.payments === undefined ||
                        node.metadata.payments === null
                    ) {
                        break;
                    }
                    if (!node.metadata.lastChangedDownpaymentProperty)
                        node.metadata.lastChangedDownpaymentProperty =
                            DownpaymentRate;

                    const payment = Math.round(threadContext.initialPayment);
                    const price = threadContext.price;
                    const start = threadContext.startDate;

                    this.updateRenewals(threadContext);

                    // updating current thread w/ de context info only if payment changes
                    if (Math.round(node.metadata.payments) !== payment) {
                        node.metadata.payments = payment.toString() + ".00";
                        node.nodeDetail =
                            processNodeValueForCanvasDisplay(payment);
                        node.metadata.value = payment;
                        if (node.metadata.isNew) {
                            node.metadata.price = price.toString();
                            node.metadata.start = start;
                            node.metadata["first payment"] =
                                threadContext.startDate;
                        }

                        this.updateDownpaymentInfo(
                            node,
                            gotDecisionEngineRes,
                            threadContext
                        );

                        this.reloadManagerAndCanvas();
                    }
                    break;
                }
                // case propertyTransferTaxObject.constant(): {
                //     // address warning system
                //     if (contextData[threadId]["addressWarning"]) {
                //         // set addressError and flag to false so warning doesn't keep triggering;
                //         // store house ids to avoid repeated warnings after engine recalculations
                //         contextData[threadId]["addressWarning"] = false;

                //         if (!node.metadata["addressWarningsIssued"]) {
                //             node.metadata["addressWarningsIssued"] = [];
                //         }

                //         node.metadata["addressWarningsIssued"].push(
                //             contextData[threadId]["houseId"]
                //         );

                //         throwError(
                //             "warning",
                //             "Compatibility Warning",
                //             "You have connected a non-Canadian house, " +
                //                 "placeholder house, or house with an unspecified city " +
                //                 "to a Canadian PTT node. This may lead to inaccurate calculations."
                //         );
                //     }
                //     let pttValue = contextData[threadId]["tax"];
                //     if (node.metadata.estimatedTax === pttValue) {
                //         break;
                //     }
                //     node.metadata.estimatedTax = pttValue;
                //     node.metadata.value = pttValue;
                //     node.nodeDetail =
                //         processNodeValueForCanvasDisplay(pttValue);
                //     this.reloadManagerAndCanvas();
                //     break;
                // }
                // case Employee: {
                //     let payment = Math.round(contextData.firstWagePayment);
                //     node.metadata.firstWagePayment = payment;

                //     if (gotDecisionEngineRes || !node.nodeDetail) {
                //         node.metadata.value = payment;
                //         node.nodeDetail =
                //             processNodeValueForCanvasDisplay(payment);

                //         this.reloadManagerAndCanvas();
                //     }

                //     break;
                // }
                // case Pension: {
                //     //debugger;
                //     let monthValue = processNodeValueForCanvasDisplay(
                //         contextData.returns
                //     );
                //     if (node.nodeDetail !== monthValue && monthValue !== "") {
                //         if (!valueDoesNotExist(contextData.returns))
                //             node.metadata.value = Math.round(
                //                 contextData.returns
                //             );
                //         node.nodeDetail = monthValue;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case propertyResaleObject.constant(): {
                //     if (!contextData) break;
                //     const { signature } = this.props.highlightedThread;
                //     const profits = contextData.resaleProfit[signature];
                //     const salePrice = contextData.finalResalePrice[signature];
                //     const processedPrice =
                //         processNodeValueForCanvasDisplay(salePrice);
                //     if (
                //         node.metadata.houseValue === "marketValue" &&
                //         node.metadata.resalePrice !== salePrice
                //     ) {
                //         node.metadata.resalePrice = salePrice;
                //     }
                //     if (node.metadata.value !== processedPrice) {
                //         node.nodeDetail = processedPrice;
                //         node.metadata.value = processedPrice;
                //         node.metadata.profits = profits;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case RealEstateFee: {
                //     if (!contextData) break;

                //     const { signature } = this.props.highlightedThread;
                //     if (node.metadata.lastChangedFeeProperty === "amount") {
                //         node.metadata.feePercent =
                //             100 *
                //             (contextData.totalFee[signature] /
                //                 (contextData.priceAfterFee[signature] +
                //                     contextData.totalFee[signature]));
                //     } else {
                //         node.metadata.feeAmount =
                //             contextData.totalFee[signature];
                //     }

                //     const processedFee = processNodeValueForCanvasDisplay(
                //         contextData.totalFee[signature]
                //     );
                //     if (node.nodeDetail !== processedFee) {
                //         node.metadata.value = node.metadata.feeAmount;
                //         node.nodeDetail = processedFee;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case MortgageInsurance: {
                //     if (!contextData) break;
                //     const threadId = this.props.highlightedThread.signature;
                //     const threadContext = contextData[threadId];
                //     if (node.metadata.useAutoValue) {
                //         node.metadata.premiumAmount =
                //             threadContext.premiumAmount;
                //         node.metadata.premiumPercent =
                //             threadContext.premiumPercent;
                //     } else if (
                //         node.metadata.lastChangedPremiumProperty === "percent"
                //     ) {
                //         node.metadata.premiumAmount =
                //             threadContext.premiumAmount;
                //     } else {
                //         node.metadata.premiumPercent =
                //             threadContext.premiumPercent;
                //     }
                //     node.metadata.taxAmount = threadContext.taxAmount;
                //     node.metadata.taxRate = threadContext.taxRate;
                //     let displayValue =
                //         node.metadata.paymentMethod === "Lump Sum" ||
                //         !node.metadata.mortgageAmortization
                //             ? threadContext.premiumAmount +
                //               threadContext.taxAmount
                //             : (threadContext.premiumAmount +
                //                   threadContext.taxAmount) /
                //               (12 *
                //                   node.metadata.mortgageAmortization.split(
                //                       " "
                //                   )[0]);

                //     const totalAmount =
                //         processNodeValueForCanvasDisplay(displayValue);
                //     if (node.nodeDetail !== totalAmount) {
                //         node.nodeDetail = totalAmount;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case propertyValueObject.constant(): {
                //     if (!contextData) break;
                //     const threadId = this.props.highlightedThread.signature;
                //     const processedVal = processNodeValueForCanvasDisplay(
                //         node.metadata.propertyValue
                //     );
                //     const curVal = contextData.houseValue[threadId];
                //     const remPrincipal =
                //         contextData.remainingPrincipal[threadId];
                //     node.metadata.presentValue = curVal;
                //     node.metadata.remainingPrincipal = remPrincipal;
                //     node.metadata.equity = curVal - remPrincipal;

                //     if (node.metadata.value !== node.metadata.propertyValue) {
                //         node.metadata.value = node.metadata.propertyValue;
                //         if (!node.nodeDetail) node.nodeDetail = processedVal;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case ClosingCosts: {
                //     if (!contextData) break;
                //     const threadId = this.props.highlightedThread.signature;
                //     const threadValue = contextData[threadId];
                //     const processedVal =
                //         processNodeValueForCanvasDisplay(threadValue);
                //     if (node.nodeDetail !== processedVal) {
                //         node.nodeDetail = processedVal;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case homeMaintenanceObject.constant(): {
                //     if (!contextData) break;
                //     const threadId = this.props.highlightedThread.signature;
                //     const threadVal = -1 * contextData.monthlyValue[threadId];
                //     const processedVal =
                //         processNodeValueForCanvasDisplay(threadVal);
                //     if (node.metadata.value !== threadVal) {
                //         node.metadata.value = threadVal;
                //         node.nodeDetail = processedVal;
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case ShortTermRental: {
                //     if (!contextData) break;
                //     let val;
                //     if (contextData.monthsOperated <= 0) {
                //         val = 0;
                //     } else {
                //         val =
                //             contextData.totalRevenue /
                //             contextData.monthsOperated;
                //     }
                //     if (node.metadata.value !== val) {
                //         node.metadata.value = val;
                //         node.nodeDetail = processNodeValueForCanvasDisplay(val);
                //         this.reloadManagerAndCanvas();
                //     }
                //     break;
                // }
                // case IncomeTax: {
                //     if (!contextData || !contextData.taxGraph) break;
                //     const threadData =
                //         contextData.taxGraph[
                //             this.props.highlightedThread.signature
                //         ];
                //     if (threadData) {
                //         let totalTax = 0;
                //         Object.keys(threadData).forEach((year) => {
                //             if (
                //                 threadData[Number(year)] &&
                //                 threadData[Number(year)].totalTax
                //             )
                //                 totalTax += threadData[Number(year)].totalTax;
                //         });
                //         const averageTax =
                //             totalTax / Object.keys(threadData).length / 12;
                //         if (node.metadata.value !== Math.round(averageTax)) {
                //             node.metadata.value = Math.round(averageTax);
                //             node.value = Math.round(averageTax);
                //             node.nodeDetail = processNodeValueForCanvasDisplay(
                //                 Math.round(averageTax)
                //             );
                //             this.reloadManagerAndCanvas();
                //         }
                //     }
                //     break;
                // }
                default:
            }
        }
    };

    getPttValues = async () => {
        try {
            return await this.props.getAdminValues("PTTValues");
        } catch (err) {
            return null;
        }
    };

    updateFeeData = (node, contextData, processedFee) => {
        const totalFee = Math.round(contextData.totalFee);
        if (node.metadata.lastChangedFeeProperty === "amount") {
            node.metadata.feePercent = (
                (node.metadata.feeAmount / node.metadata.resalePrice) *
                100
            ).toFixed(2);
        } else if (node.metadata.lastChangedFeeProperty === "percent") {
            node.metadata.feeAmount = (
                (node.metadata.feePercent / 100) *
                node.metadata.feeAmount
            ).toFixed(2);
        }
        if (valueDoesNotExist(totalFee)) {
            processedFee = processNodeValueForCanvasDisplay(
                node.metadata.feeAmount
            );
        } else {
            processedFee = processNodeValueForCanvasDisplay(totalFee);
        }
        node.metadata.feeAmount = processedFee;
    };

    updateDownpaymentInfo = (node, gotDecisionEngineRes, threadContext) => {
        node.metadata.isDownpayment = threadContext.isDownpayment;
        // If downpayment data is edited locally, it will get overridden by the old fe context,
        // since componentdidupdate is triggered before we get the response from DE.
        // Since you can't edit the downpayment data locally when an upstream downpayment node
        // handles the downpayment data, it's safe to update there too.
        if (gotDecisionEngineRes || threadContext.isDownpayment) {
            // safe to override here, as were dealing w/ fresh DE values that come straight
            // from the updated local ones
            node.metadata.downpaymentRate = threadContext.downpaymentRate;
            node.metadata.downpayment = threadContext.downpayment;
        } else if (
            node.metadata.lastChangedDownpaymentProperty === DownpaymentRate
        ) {
            // the downpayment amount will be recalculated in the de, so we
            // can override it - we don't want to override the local rate here,
            // However the rate is recalculated for safety, just in case we're switching between
            // downpayment dependent contexts and not
            const downpayment = threadContext.downpayment;
            node.metadata.downpayment = downpayment;
            node.metadata.downpaymentRate =
                (downpayment / node.metadata.price) * 100;
        } else if (
            node.metadata.lastChangedDownpaymentProperty === DownpaymentAmount
        ) {
            const rate = threadContext.downpaymentRate;
            node.metadata.downpaymentRate = rate;
            node.metadata.downpayment = (rate / 100) * node.metadata.price;
        }
    };

    // Updates any renewal/refinance nodes based on historical payments
    // cause by corresponding renewals/refinance mods.
    updateRenewals = (contextData) => {
        let changed = false;
        const renewals = contextData.renewals;
        for (const renewalId in renewals) {
            const node = this.props.manager._findEvent(renewalId);
            if (!node) continue;
            const processedVal = processNodeValueForCanvasDisplay(
                renewals[renewalId]
            );
            if (processedVal !== node.nodeDetail) {
                changed = true;
                node.nodeDetail = processedVal;
            }
        }
        if (changed) {
            this.reloadManagerAndCanvas();
        }
    };

    // used by MortgageInsurance to find IDs of mortgages which will be modified
    getMortgageInsuranceMortgages = (node, scenarioThreads) => {
        const nodeIndicesWithinThreads = [];
        const threadsWithNode = [];
        scenarioThreads.forEach((thread) => {
            for (let i = 0; i < thread.nodes.length; i++) {
                if (node.id === thread.nodes[i].id) {
                    threadsWithNode.push(thread);
                    nodeIndicesWithinThreads.push(i);
                    break;
                }
            }
        });
        const modifiedMortgageIds = [];
        for (let i = 0; i < threadsWithNode.length; i++) {
            let k = nodeIndicesWithinThreads[i] - 1;
            while (k >= 0) {
                if (threadsWithNode[i].nodes[k].type === "Mortgage") {
                    modifiedMortgageIds.push(threadsWithNode[i].nodes[k].id);
                }
                k--;
            }
        }

        return modifiedMortgageIds;
    };

    isContextSafeToProcess = (node, context) => {
        return (
            node.metadata && context[node.type] && context[node.type][node.id]
        );
    };

    reloadManagerAndCanvas() {
        this.props.manager.calculate(false);

        this.setState({ reload: true });
    }

    toggleScenarios = () => {
        this.setState({ showScenarios: !this.state.showScenarios });
    };

    addEventType = (type) => {
        this.props.changeSelectedOption(type);
    };

    onClickFilter = (value) => {
        this.setState({ filterThread: value });
    };

    onClickGraph = () => {
        if (this.props.nodeGraphState !== "default")
            this.props.setNodeGraphState("default");
        else if (this.state.showRightDisplay)
            this.props.setNodeGraphState("normal");
        else if (!this.state.showRightDisplay)
            this.props.setNodeGraphState("normal-wide");
    };

    expandNodeGraph = () => {
        if (
            this.props.nodeGraphState === "normal-wide" &&
            !this.state.showRightDisplay
        )
            this.props.setNodeGraphState("expanded-wide");

        if (
            this.props.nodeGraphState === "normal" &&
            this.state.showRightDisplay
        )
            this.props.setNodeGraphState("expanded");

        if (
            this.props.nodeGraphState === "expanded-wide" &&
            !this.state.showRightDisplay
        )
            this.props.setNodeGraphState("normal-wide");

        if (
            this.props.nodeGraphState === "expanded" &&
            this.state.showRightDisplay
        )
            this.props.setNodeGraphState("normal");
    };

    toggleMenu = () => {
        this.setState({ showNodeMenu: !this.state.showNodeMenu });
    };

    cancelToast = () => {
        this.cancelDeletePrompt();
        this.setState({ toastData: null });
    };

    onHandleEventPasteToast = (event) => {
        this.setState(
            {
                toastData: event,
                upgradePlan: event.upgrade,
            },
            () => {
                setTimeout(() => {
                    this.setState({ toastData: null, upgradePlan: false });
                }, 7000);
            }
        );
    };

    upgradePlan = () => {
        this.props.history.push("/billing");
    };

    onHandleNext = () => {
        const { manager } = this.props;

        const data = manager.exportData();
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (this.props.onboardingData === 14) {
            const { manager, loadedScenario } = this.props;
            if (!loggedInUser) {
                const data = manager.exportData();
                const normalScenario = {
                    ...loadedScenario,
                    data,
                };

                this.props.onboardingScenario(normalScenario);
                this.props.loadScenario(
                    normalScenario,
                    manager,
                    this.props.baselineDataManager
                );
                Mixpanel.track("Non User Finished Tour", {
                    name: "alias",
                });
            } else {
                Mixpanel.track("User Finished Tour", {
                    name: loggedInUser.name,
                    email: loggedInUser.email,
                });
            }
            this.props.setOnboardingData(null);
        } else {
            if (this.props.onboardingData === 2 && data.nodes.length <= 3) {
                const normalData = manager.exportData();
                if (normalData) {
                    manager.addOnboardingScenario(
                        decisionObject.constant(),
                        decisionMetadata,
                        [manager.nodes[normalData.nodes[1].id]],
                        [manager.nodes[normalData.nodes[2].id]]
                    );
                }
            } else if (
                this.props.onboardingData === 3 &&
                data.nodes.length <= 4
            ) {
                const normalData = manager.exportData();
                if (normalData) {
                    manager.addOnboardingScenario(
                        "House",
                        house1Metadata,
                        [manager.nodes[normalData.nodes[3].id]],
                        []
                    );
                }
            } else if (
                this.props.onboardingData === 4 &&
                data.nodes.length <= 5
            ) {
                const normalData = manager.exportData();
                if (normalData) {
                    manager.addOnboardingScenario(
                        "Mortgage",
                        mortgage1Metadata,
                        [manager.nodes[normalData.nodes[4].id]],
                        []
                    );
                }
            } else if (
                this.props.onboardingData === 6 &&
                data.nodes.length <= 6
            ) {
                const normalData = manager.exportData();
                if (normalData) {
                    manager.addOnboardingScenario(
                        "IncomeTax",
                        incomeTaxMetadata,
                        [manager.nodes[normalData.nodes[5].id]],
                        []
                    );
                }
            } else if (this.props.onboardingData === 7) {
                this.props.setNodeGraphState("normal");
            } else if (this.props.onboardingData === 8) {
                this.props.setNodeGraphState("default");
            } else if (this.props.onboardingData === 9) {
                const normalData = manager.exportData();
                //this is where it's re-graphing
                if (normalData) {
                    const normalData = manager.exportData();

                    const node = manager._findEvent(normalData.nodes[2].id);

                    node.setChildren(
                        [manager.nodes[normalData.nodes[6].id]],
                        true
                    );
                    manager.calculate();
                    // manager._updateScenarioCanvas();
                }
            } else if (
                this.props.onboardingData === 10 &&
                data.nodes.length <= 7
            ) {
                //check the node count
                const normalData = manager.exportData();
                if (normalData) {
                    manager.addOnboardingScenario(
                        decisionObject.constant(),
                        mortgageDecision,
                        [manager.nodes[normalData.nodes[4].id]],
                        [manager.nodes[normalData.nodes[5].id]]
                    );
                }
            } else if (
                this.props.onboardingData === 11 &&
                data.nodes.length <= 8
            ) {
                const normalData = manager.exportData();

                if (normalData) {
                    manager.addOnboardingScenario(
                        "Mortgage",
                        mortgage2Metadata,
                        [manager.nodes[normalData.nodes[7].id]],
                        [manager.nodes[normalData.nodes[6].id]]
                    );
                }
            } else if (this.props.onboardingData === 12) {
                const normalData = manager.exportData();
                if (normalData) {
                    const node = manager._findEvent(normalData.nodes[8].id);

                    if (!node.isLocked()) {
                        node.toggleLocked();
                        manager.calculate();
                    }
                }

                // manager.calculate();
            } else if (this.props.onboardingData === 13) {
                const normalData = manager.exportData();
                if (normalData) {
                    const node = manager._findEvent(normalData.nodes[7].id);

                    if (!node.isLocked()) {
                        node.toggleLocked();
                        manager.calculate();
                    }
                }
            }

            this.props.setOnboardingData(this.props.onboardingData + 1);
        }

        if (loggedInUser) {
            Mixpanel.track("User Clicked Tour Next Button", {
                name: loggedInUser.name,
                email: loggedInUser.email,
                step: this.props.onboardingData,
            });
        } else {
            Mixpanel.track("Non User Clicked Tour Next Button", {
                step: this.props.onboardingData,
            });
        }
    };

    onHandleCloseTour = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { manager, userScenarios } = this.props;
        if (!loggedInUser) {
            Mixpanel.track("Non User Skipped Tour", {
                name: "alias",
            });
            this.props.loadScenario(
                userScenarios[0],
                manager,
                this.props.baselineDataManager
            );
        } else {
            Mixpanel.track("User Skipped Tour", {
                name: loggedInUser.name,
                email: loggedInUser.email,
            });
        }
        this.props.setOnboardingData(null);
        this.setState({ exitTourModal: false });
    };

    onHandlePrompt = () => {
        this.setState({ exitTourModal: !this.state.exitTourModal });
    };

    onhandleBack = () => {
        const { onboardingData } = this.props;
        const { manager } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const normalData = manager.exportData();
        let deleteNode;

        if (onboardingData === 3) {
            deleteNode = manager.deleteEvent(normalData.nodes[3].id);
        } else if (onboardingData === 4) {
            deleteNode = manager.deleteEvent(normalData.nodes[4].id);
        } else if (onboardingData === 5) {
            deleteNode = manager.deleteEvent(normalData.nodes[5].id);
        } else if (onboardingData === 7) {
            deleteNode = manager.deleteEvent(normalData.nodes[6].id);
        } else if (onboardingData === 8) {
            this.props.setNodeGraphState("default");
        } else if (onboardingData === 10) {
            manager.separateNodes(
                normalData.nodes[2].id,
                normalData.nodes[6].id
            );
        } else if (onboardingData === 11) {
            deleteNode = manager.deleteEvent(normalData.nodes[7].id);
        } else if (onboardingData === 12) {
            deleteNode = manager.deleteEvent(normalData.nodes[8].id);
        } else if (onboardingData === 13) {
            const node = manager._findEvent(normalData.nodes[8].id);
            node.toggleLocked();
            manager.calculate();
        } else if (onboardingData === 14) {
            const node = manager._findEvent(normalData.nodes[7].id);
            node.toggleLocked();
            manager.calculate();
        }

        if (deleteNode) {
            const { root, newRoot, toDelete } = deleteNode;
            manager.deleteNodes(root.id, newRoot, toDelete);
            deleteNode = null;
            manager.calculate();
        }

        this.props.setOnboardingData(this.props.onboardingData - 1);
        this.props.setFocus(null);

        if (loggedInUser) {
            Mixpanel.track("User Clicked Tour Back Button", {
                name: loggedInUser.name,
                email: loggedInUser.email,
                step: this.props.onboardingData,
            });
        } else {
            Mixpanel.track("Non User Clicked Tour Back Button", {
                step: this.props.onboardingData,
            });
        }
    };

    handleLandingPageClose = () => {
        // this.createScenario();
        // this.props.setOnboardingData(1);
        this.setState({ landingPage: false });
        this.props.setOnboardingState(WELCOME);
    };

    onHandleOptionSelected = (value) => {
        this.setState({ optionSelected: value });
    };

    handleCloseModal = () => {
        this.props.closeOption();
        this.props.onhandleFocusedInput(null);
        this.onHandleOptionSelected(null);
    };

    toggleAddingLibraryEvent = (newValue) => {
        this.props.toggleIsAddingLibraryEvent(newValue);
    };

    ClickRightDisplayChangeTabs = (tabName) => {
        if (this.state.rightDisplayContent === tabName) {
            this.setState({
                rightDisplayContent: "closed",
                showRightDisplay: false,
            });
        } else {
            this.setState({
                rightDisplayContent: tabName,
                showRightDisplay: true,
            });
        }
    };

    ClickGraphPanelChangeTabs = (tabName) => {
        if (
            this.props.nodeGraphState !== "default" &&
            this.state.graphPanelTabSelected === tabName
        ) {
            this.props.setNodeGraphState("default");
            this.setState({
                graphPanelTabSelected: "closed",
                showGraphPanel: false,
            });
        } else if (
            (this.props.nodeGraphState === "expanded" ||
                this.props.nodeGraphState === "expanded-wide") &&
            this.state.graphPanelTabSelected !== tabName
        ) {
            this.props.nodeGraphState === "expanded"
                ? this.props.setNodeGraphState("expanded")
                : this.props.setNodeGraphState("expanded-wide");
            this.setState({
                graphPanelTabSelected: tabName,
                showGraphPanel: true,
            });
        } else if (this.state.showRightDisplay) {
            this.props.setNodeGraphState("normal");
            this.setState({
                graphPanelTabSelected: tabName,
                showGraphPanel: true,
            });
        } else if (!this.state.showRightDisplay) {
            this.props.setNodeGraphState("normal-wide");
            this.setState({
                graphPanelTabSelected: tabName,
                showGraphPanel: true,
            });
        }
    };

    /**
     *
     * @returns {string} Date string formatted as "YYYY-MM-DD"
     */
    getScenarioStartDate = () => {
        // Figuring out what to send as start date for the scenario
        // defaults to today's date if no preference
        if (this.props.loadedScenario.start_date_preference === "custom")
            return this.props.loadedScenario.custom_start_date;
        else if (
            this.props.loadedScenario.start_date_preference === "monthStart"
        )
            return moment().startOf("month").format("YYYY-MM-DD");
        else return moment().format("YYYY-MM-DD");
    };

    /**
     *
     * @returns {int} thats the scenario length in years
     */
    getScenarioLength = () => {
        return this.props.loadedScenario.range;
    };

    render = () => {
        // const manager = this.modelManager;
        // const manager = this.manager; // TODO: After canvas-refactor: Audit & remove throughout this file.
        const {
            focus,
            nodeGraphState,
            showOptions,
            closeOption,
            selectedOption,
            selectedEvent,
            edit,
            onboardingData,
            calculatedThreads,
        } = this.props;
        const {
            // nodes, // TODO: After canvas-refactor: Audit & remove throughout this file.
            showScenarios,
            currentDisplay,
            deleteNode,
            upgradePlan,
            showRightDisplay,
        } = this.state;

        //this two uncommented created white screen
        // const displayGraph = scenarioCalculations.length > 0;
        const displayMenu = focus != null;

        let CanvasContainer;

        if (
            this.props.nodeGraphState === "normal-wide" &&
            this.state.showRightDisplay
        )
            this.props.setNodeGraphState("normal");

        if (
            this.props.nodeGraphState === "expanded-wide" &&
            this.state.showRightDisplay
        )
            this.props.setNodeGraphState("expanded");

        if (
            this.props.nodeGraphState === "normal" &&
            !this.state.showRightDisplay
        )
            this.props.setNodeGraphState("normal-wide");

        if (
            this.props.nodeGraphState === "expanded" &&
            !this.state.showRightDisplay
        )
            this.props.setNodeGraphState("expanded-wide");

        // Responsiveness for NODE GRAPH'S PANEL WIDTH starts here

        let GraphContainer;
        let graphTopWidth;

        if (this.state.showRightDisplay && window.innerWidth > 1999) {
            graphTopWidth = "GraphTopWidth";
        } else if (this.state.showRightDisplay && window.innerWidth < 1559) {
            graphTopWidth = "GraphMinWidth";
        } else if (
            this.state.showRightDisplay &&
            window.innerWidth > 1559 &&
            window.innerWidth < 1999
        )
            graphTopWidth = "GraphNormalWidth";

        CanvasContainer = "CanvasContainerDefault";

        switch (nodeGraphState) {
            case "default":
                GraphContainer = `GraphContainer GraphContainerDefault ${graphTopWidth}`;
                break;
            case "normal":
                GraphContainer = `GraphContainer GraphContainerNormal ${graphTopWidth}`;
                break;

            case "normal-wide":
                GraphContainer =
                    "GraphContainer GraphContainerNormal GraphContainerWide";
                break;

            case "expanded":
                GraphContainer = `GraphContainer GraphContainerExpanded ${graphTopWidth}`;
                break;

            case "expanded-wide":
                GraphContainer =
                    "GraphContainer GraphContainerExpanded GraphContainerWide";
                break;
            default:
        }

        // Responsiveness for BOTTOM PANEL'S PANEL WIDTH ends here

        let bottomPanelComponent;

        // const mapKey = "AIzaSyAgizqFuqerHluNHVCJIBCJ0xAmctYIxEg";

        switch (this.state.graphPanelTabSelected) {
            case "EventGraph":
                bottomPanelComponent = (
                    <ChartGraph
                        // TODO: None of these should be required to be passed down
                        showRightDisplay={this.state.showRightDisplay}
                        nodeGraphState={this.props.nodeGraphState}
                        setNodeGraphState={this.props.setNodeGraphState}
                    />
                );
                break;
            case "TableChart":
                bottomPanelComponent = (
                    <div className="table-chart-tab__container">
                        <div className="agGridTabsContainer">
                            <AgGridView />
                        </div>
                    </div>
                );
                break;
            case "Leaderboard":
                bottomPanelComponent = (
                    <div className="table-chart-tab__container">
                        <div
                            className={
                                this.props.nodeGraphState === "expanded" ||
                                this.props.nodeGraphState === "expanded-wide"
                                    ? "table-chart-tab__img1-wrapper--expanded"
                                    : "table-chart-tab__img1-wrapper"
                            }
                        >
                            <img
                                className={
                                    this.props.nodeGraphState === "expanded" ||
                                    this.props.nodeGraphState ===
                                        "expanded-wide"
                                        ? "table-chart-tab__bg-img--pie-charts-expanded"
                                        : "table-chart-tab__bg-img--pie-charts-normal"
                                }
                                src={LeaderboardImg}
                                alt="blurred upcoming feature"
                            />
                            <div className="table-chart-tab__blur-screen">
                                <div className="table-chart-tab__text-wrapper">
                                    <span>Feature coming soon!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case "Map":
                bottomPanelComponent = (
                    <div className="table-chart-tab__container">
                        {/* {MAP_TAB_FLAG_EXPERIMENTAL && (
                            <div className="table-chart-tab__controls-wrapper">
                                <button
                                    className="table-chart-tab__button"
                                    onClick={() =>
                                        this.handleBottomPanelViews(
                                            "allThreads"
                                        )
                                    }
                                >
                                    All Threads
                                </button>
                                <button
                                    className="table-chart-tab__button"
                                    onClick={() =>
                                        this.handleBottomPanelViews(
                                            "highlightedThread"
                                        )
                                    }
                                >
                                    Highlighted Thread
                                </button>
                                <button
                                    className="table-chart-tab__button"
                                    onClick={() => {
                                        // FLAG: Ask Daniel what we need to do here to move to redux
                                        this.handleBottomPanelViews(
                                            "selectedThreads"
                                        );
                                    }}
                                >
                                    Selected Threads
                                </button>
                            </div>
                        )} */}
                        <div
                            className={
                                this.props.nodeGraphState === "expanded" ||
                                this.props.nodeGraphState === "expanded-wide"
                                    ? "table-chart-tab__img1-wrapper--expanded"
                                    : "table-chart-tab__img1-wrapper"
                            }
                        >
                            {/* {MAP_TAB_FLAG_EXPERIMENTAL ? (
                                <GoogleMapComponent
                                    mapKey={mapKey}
                                    firstHouseEvent={this.state.firstHouseEvent}
                                    allEvents={this.state.allEvents}
                                    selectedThreadsWithEvents={
                                        this.state.selectedThreadsWithEvents
                                    }
                                    highlightedThread={
                                        this.props.highlightedThread
                                    }
                                    selectedBottomPanelView={
                                        this.state.selectedBottomPanelView
                                    }
                                />
                            ) : ( */}
                            <>
                                <img
                                    className="table-chart-tab__bg-img"
                                    src={MapImg}
                                    alt="blurred upcoming feature"
                                />
                                <div className="table-chart-tab__blur-screen">
                                    <div className="table-chart-tab__text-wrapper">
                                        <span>Feature coming soon!</span>
                                    </div>
                                </div>
                            </>
                            {/* )} */}
                        </div>
                    </div>
                );
                break;
            case "entityTable":
                bottomPanelComponent = <EntityTableView />;
                break;
            case "accountDelve":
                bottomPanelComponent = <AccountDelveView />;
                break;
            default:
        }

        let bottomPanelTabControlsClasses = ["BottomPanelTabControlsContainer"];

        if (showRightDisplay) {
            if (window.innerWidth < 2000)
                bottomPanelTabControlsClasses.push(
                    "BottomPanelTabControlsContainerOpen"
                );
            else {
                bottomPanelTabControlsClasses.push(
                    "BottomPanelTabControlsContainerOpenMaxWidth"
                );
            }
        } else {
            bottomPanelTabControlsClasses.push(
                "BottomPanelTabControlsContainerClosed"
            );
        }

        // This SWITCH below is assigning the component to the variable,
        // depending on which tab is clicked.
        let rightDisplayComponent;
        let eventMenuClass = "TabIconWrapper";
        let scenarioViewClass = "TabIconWrapper";
        let knowledgeBaseClass = "TabIconWrapper";
        let aiAssistantBaseClass = "TabIconWrapper";

        switch (this.state.rightDisplayContent) {
            case "EventMenu":
                displayMenu
                    ? (rightDisplayComponent = (
                          <div className="EventMenu">
                              <NodeMenuContainer
                                  inBaseline={false}
                                  history={this.props.history}
                                  threadsAlias={calculatedThreads}
                                  deleteEvent={this.deleteEvent}
                                  reload={this.state.reload}
                                  onHandleEventPasteToast={
                                      this.onHandleEventPasteToast
                                  }
                                  accountData={this.props.accountData}
                              />
                          </div>
                      ))
                    : (rightDisplayComponent = (
                          <div className="EmptyEventMenuContainer">
                              <div className="EmptyEventMenuTitleWrapper">
                                  <span className="EmptyEventTitle">
                                      Event Detail
                                  </span>
                              </div>
                              <div className="EmptyEventImageTextWrapper">
                                  <img
                                      className="EmptyEventImage"
                                      src={EmptyEventImage}
                                      alt="Empty Event"
                                  />
                                  <div className="EmptyEventTextWrapper">
                                      <span className="EmptyEventSubtitle">
                                          No Selected Event
                                      </span>
                                      <span className="EmptyEventText">
                                          Click on an Event in the Scenario
                                          Canvas to view details.
                                      </span>
                                  </div>
                              </div>
                          </div>
                      ));

                eventMenuClass = "TabIconWrapperActive";
                break;
            case "ScenarioView":
                rightDisplayComponent = <ScenarioView />;
                scenarioViewClass = "TabIconWrapperActive";
                break;
            case "KnowledgeBase":
                rightDisplayComponent = (
                    <div className="KnowledgeBaseContainer">
                        <div className="KnowledgeBaseTitleWrapper">
                            <span className="KnowledgeBaseTitle">
                                Knowledge Base
                            </span>
                        </div>
                        {/* <div className="KnowledgeBaseVideoCardsWrapper">
                            <a
                                className="KnowledgeBaseVideoCard"
                                href="https://youtu.be/IeML3Hd3XjI"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="KnowledgeBaseVideoThumbnail"
                                    src={CreateNewScenarioThumbnail}
                                    alt="knowledge base thumbnail"
                                />
                                <div className="KnowledgeBaseVideoTextWrapper">
                                    <span className="KnowledgeBaseVideoDetail">
                                        Video - 1:40 min
                                    </span>
                                </div>
                            </a>
                            <a
                                className="KnowledgeBaseVideoCard"
                                href="https://youtu.be/fLMmGi52EY8"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="KnowledgeBaseVideoThumbnail"
                                    src={RentVsBuyScenarioThumbnail}
                                    alt="rent vs buy thumbnail"
                                />
                                <div className="KnowledgeBaseVideoTextWrapper">
                                    <span className="KnowledgeBaseVideoDetail">
                                        Video - 12 min
                                    </span>
                                </div>
                            </a>
                        </div> */}
                        <div className="KnowledgeBaseImageTextWrapper">
                            <img
                                className="KnowledgeBaseImage"
                                src={KnowledgeBaseImg}
                                alt="Knowledge Base"
                            />
                            <div className="KnowledgeBaseTextWrapper">
                                <span className="KnowledgeBaseSubtitle">
                                    Knowledge Base
                                </span>
                                <span className="KnowledgeBaseText">
                                    Learn tips and tricks, and explore what
                                    whatifi can offer!
                                </span>
                            </div>
                        </div>
                        <a
                            className="KnowledgeBaseLink"
                            href="https://docs.whatifi.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="KnowledgeBaseButton">
                                <span className="KnowledgeBaseButtonText">
                                    Visit Knowledge Base!
                                </span>
                            </div>
                        </a>
                    </div>
                );
                knowledgeBaseClass = "TabIconWrapperActive";
                break;
            default:
        }

        return (
            <div className="MainContainer">
                <div id="MainContainerCanvasContainer" />
                <div className="LeftDisplay">
                    <div className={CanvasContainer}>
                        <ScenariosPanel
                            toggleScenarios={this.toggleScenarios}
                            showScenarios={showScenarios}
                            openLogin={this.props.openLogin}
                            history={this.props.history}
                            setNodeGraphState={this.props.setNodeGraphState}
                            promptScenarioCreation={
                                this.state.promptScenarioCreation
                            }
                            sampleScenarioCreation={
                                this.state.sampleScenarioCreation
                            }
                            promptScenarioEdit={this.state.promptScenarioEdit}
                            sampleScenarioEdit={this.state.sampleScenarioEdit}
                            togglePromptScenarioCreation={
                                this.togglePromptScenarioCreation
                            }
                            toggleSampleScenarioCreation={
                                this.toggleSampleScenarioCreation
                            }
                            setSampleScenarioCreation={
                                this.setSampleScenarioCreation
                            }
                            togglePromptScenarioEdit={
                                this.togglePromptScenarioEdit
                            }
                            setSampleScenarioEdit={this.setSampleScenarioEdit}
                            focus={this.props.focus}
                            showDropdown={this.state.showDropdown}
                            rightDisplayContent={this.state.rightDisplayContent}
                        />
                        <CanvasStage
                            isDashboard={this.props.match.path === "/dashboard"}
                        />
                    </div>
                    <div className={GraphContainer}>
                        {process.env.NODE_ENV === "development" && (
                            <DashboardSpeedDial />
                        )}
                        {this.props.highlightedThread && (
                            <BottomPanelTabControls
                                showRightDisplay={showRightDisplay}
                                nodeGraphState={this.props.nodeGraphState}
                                onClickGraph={this.onClickGraph}
                                ClickGraphPanelChangeTabs={
                                    this.ClickGraphPanelChangeTabs
                                }
                                graphPanelTabSelected={
                                    this.state.graphPanelTabSelected
                                }
                                currentDisplay={currentDisplay}
                                setDisplay={this.setDisplay}
                                onboardingData={onboardingData}
                            />
                        )}
                        {nodeGraphState !== "default" && (
                            <div className="bottom-panel__component-container">
                                <div className="bottom-panel__displayContainer">
                                    {this.state.graphPanelTabSelected ===
                                        "EventGraph" && <SelectionFilter />}
                                    {bottomPanelComponent}
                                </div>
                                <div className="BottomPanelExpandContainer">
                                    <div
                                        className="BottomPanelExpandBgCircle"
                                        onClick={this.expandNodeGraph}
                                    >
                                        <img
                                            alt="expand graph"
                                            src={BottomPanelExpandIcon}
                                            className={
                                                nodeGraphState === "expanded" ||
                                                nodeGraphState ===
                                                    "expanded-wide"
                                                    ? "BottomPanelExpandIconExpanded"
                                                    : "BottomPanelExpandIcon"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <RightDisplayPanel
                    showRightDisplay={showRightDisplay}
                    eventMenuClass={eventMenuClass}
                    scenarioViewClass={scenarioViewClass}
                    knowledgeBaseClass={knowledgeBaseClass}
                    ClickRightDisplayChangeTabs={
                        this.ClickRightDisplayChangeTabs
                    }
                    rightDisplayComponent={rightDisplayComponent}
                />

                <Modal
                    open={Boolean(showOptions || selectedEvent)}
                    onClose={this.handleCloseModal}
                >
                    <div>
                        <EventsModal
                            option={true}
                            onHandleClose={this.handleCloseModal}
                            edit={edit}
                            selectedEvent={selectedEvent}
                            optionSelected={this.state.optionSelected}
                            hoveredInput={this.props.hoveredInput}
                            clickAway={true}
                        >
                            <Options
                                history={this.props.history}
                                selectedOption={selectedOption}
                                selectedEvent={selectedEvent}
                                addEventType={this.addEventType}
                                optionSelected={this.state.optionSelected}
                                onHandleOptionSelected={
                                    this.onHandleOptionSelected
                                }
                                onboardingData={this.props.onboardingData}
                                close={closeOption}
                                toggleAddingLibraryEvent={
                                    this.props.toggleIsAddingLibraryEvent
                                }
                                isAddingLibraryEvent={
                                    this.props.isAddingLibraryEvent
                                }
                            />
                        </EventsModal>
                    </div>
                </Modal>
                {this.state.toastData && (
                    <EventsToast
                        data={this.state.toastData}
                        close={this.cancelToast}
                        history={this.props.history}
                    >
                        {deleteNode && (
                            <div className="promptButtonContainer">
                                <div
                                    onClick={this.cancelDeletePrompt}
                                    className="deletePromptButton"
                                >
                                    Cancel
                                </div>
                                <div
                                    onClick={this.deletePrompt}
                                    className="deletePromptButton"
                                >
                                    Yes
                                </div>
                            </div>
                        )}
                        {upgradePlan && (
                            <div className="promptButtonContainer">
                                <div
                                    onClick={this.cancelDeletePrompt}
                                    className="deletePromptButton"
                                >
                                    Close
                                </div>
                                <div
                                    onClick={this.upgradePlan}
                                    className="deletePromptButton"
                                >
                                    Upgrade
                                </div>
                            </div>
                        )}
                    </EventsToast>
                )}
                {this.props.onboardingData && (
                    <OnboardingModal
                        step={this.props.onboardingData}
                        onHandleNext={this.onHandleNext}
                        close={this.onHandlePrompt}
                        onhandleBack={this.onhandleBack}
                    />
                )}

                {this.state.exitTourModal && (
                    <EventsModal
                        onHandleClose={this.onHandlePrompt}
                        confirm={true}
                    >
                        <div className="headerText">Exit Product Tour?</div>
                        <div className="subHeaderText">
                            Are you sure you want to exit the product tour?{" "}
                            <br />
                            You can always retake the tour. It can be found
                            under the{" "}
                            <span className="highlightedTour">
                                Help {">"} Product Tour
                            </span>{" "}
                            section at the top of the page.
                        </div>
                        <div className="promptButtonContainer">
                            <div
                                className="buttonText"
                                onClick={this.onHandlePrompt}
                            >
                                Cancel
                            </div>
                            <div
                                onClick={this.onHandleCloseTour}
                                className="buttonOrange"
                            >
                                Yes, Exit
                            </div>
                        </div>
                    </EventsModal>
                )}
                {/* COMMENTED OUT WHILE WE FIGURE OUT WHERE / WHEN IS BEST TO SHOW DISCLAIMER
                    <DisclaimerPopUp
                    disclaimerAccepted={this.props.disclaimerAccepted}
                    onHandleAcceptDisclaimer={
                        this.props.onHandleAcceptDisclaimer
                    }
                /> */}
                <UltraZoomThreadButtonExperimental />
                <PasteOptionModal />
                <CopyScenarioModal />
            </div>
        );
    };
}

const mapStateToProps = appMapState((state) => {
    const zoomedThreadId = state?.threadsList?.zoomedThreadId;

    return {
        zoomedThreadId,
    };
});

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

export default connect(mapStateToProps, mapDispatchToProps)(Main);
